import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";

import React from "react";

import { BrowserRouter } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import Analytics from "react-router-ga";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Upgrade from "./Upgrade";

function currentBrowserCheck() {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ");

  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
    // If Internet Explorer, return false
    return false;
  } // If current browser, return true

  return true;
}

Sentry.init({
  dsn: "https://617da253d13c4d3da2fbc8ff278602da@o345094.ingest.sentry.io/5432414",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

currentBrowserCheck();

ReactDOM.render(
  <BrowserRouter>
    <Analytics id="UA-5665489-2">
      <CookiesProvider>{currentBrowserCheck() ? <App /> : <Upgrade />}</CookiesProvider>
    </Analytics>
  </BrowserRouter>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
