import React, { useState, useEffect, useRef } from "react";

import { withRouter, Link as link } from "react-router-dom";

import styled, { css } from "styled-components/macro";

import { vars, colors, font, mediaQuery } from "../../helpers/styles";
import { Logo } from "../Icons";

const ScrolledNav = (props) => {
  const [menuActive, setMenuActive] = useState(false);
  const [lockedScroll, setLockedScroll] = useState(null);

  const refInventory = useRef();
  const refCompany = useRef();
  const refContact = useRef();
  const refServices = useRef();
  const refFinancialAdvisoryServices = useRef();

  const locPath = props.location.pathname;

  const toggleNav = (e) => {
    if (!menuActive) {
      if (props.lockScroll) {
        setLockedScroll(true);
      } else {
        props.toggleLockScroll(true);
      }
      setMenuActive(true);
    } else {
      if (lockedScroll) {
        setLockedScroll(false);
      } else {
        props.toggleLockScroll(false);
      }
      setMenuActive(false);
    }
  };

  const linkClick = (e, path) => {
    if (path === props.history.location.pathname) {
      // collapse scrolledNav menu (if applicable)
      setMenuActive(false);

      // unlock scroll (if applicable)
      props.toggleLockScroll(false);

      return;
    }

    e.preventDefault();
    const {
      history: { push },
    } = props;

    // unlock scroll (if applicable)
    props.toggleLockScroll(false);

    // hide scrolled nav
    props.toggleScrolledNav(false);

    if (menuActive) {
      // collapse scrolledNav menu (if applicable)
      setMenuActive(false);

      // after 400ms, push
      setTimeout(() => {
        push(path);
        window.scrollTop = 0;
      }, 400);
    } else {
      // after 200ms, push
      setTimeout(() => {
        push(path);
        window.scrollTop = 0;
      }, 200);
    }
  };

  useEffect(() => {
    const closeNavEsc = (e) => {
      if (menuActive && e.keyCode === 27) {
        toggleNav();
      }
    };

    window.addEventListener("keydown", closeNavEsc);

    // unmount
    return () => window.removeEventListener("keydown", closeNavEsc);
  });

  if (locPath !== "/letter") {
    return (
      <NavBar scrolledNav={props.scrolledNav} notification={props.notification.is_visible}>
        <NavScrim menuActive={menuActive} onClick={toggleNav} />
        <LogoBox to="/" onClick={(e) => linkClick(e, "/")} params={{ isActive: false }}>
          <Logo />
        </LogoBox>
        <NavContent menuActive={menuActive}>
          <Links>
            <Link
              to="/"
              ref={refInventory}
              onClick={(e) => linkClick(e, "/")}
              params={{
                isActive:
                  locPath !== "/company" &&
                  locPath !== "/contact" &&
                  locPath !== "/services" &&
                  locPath !== "/financial-advisory-services",
              }}
            >
              <LinkText>Inventory</LinkText>
            </Link>
            <Link
              to="/company"
              ref={refCompany}
              onClick={(e) => linkClick(e, "/company")}
              params={{ isActive: locPath === "/company" }}
            >
              <LinkText>Company</LinkText>
            </Link>
            <Link
              to="/services"
              ref={refServices}
              onClick={(e) => linkClick(e, "/services")}
              params={{ isActive: locPath === "/services" }}
            >
              <LinkText>Services</LinkText>
            </Link>
            <Link
              to="/financial-advisory-services"
              ref={refFinancialAdvisoryServices}
              onClick={(e) => linkClick(e, "/financial-advisory-services")}
              params={{ isActive: locPath === "/financial-advisory-services" }}
            >
              <LinkText>Financial Advisory Services</LinkText>
            </Link>
            <Link
              to="/contact"
              ref={refContact}
              onClick={(e) => linkClick(e, "/contact")}
              params={{ isActive: locPath === "/contact" }}
            >
              <LinkText>Contact</LinkText>
            </Link>
          </Links>
          <ContactLinks>
            <ContactLink href="mailto:info@avprojets.com" contact>
              <LinkText>info@avprojets.com</LinkText>
            </ContactLink>
            <ContactLink href="tel:14105731515" contact>
              <LinkText>+1.410.573.1515</LinkText>
            </ContactLink>
          </ContactLinks>
          <SocialLinks>
            <SocialLink
              href="https://www.linkedin.com/company/avpro-inc./?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
              social
            >
              <LinkText>Linkedin</LinkText>
            </SocialLink>
            <SocialLink href="https://www.facebook.com/avprojets/" target="_blank" rel="noopener noreferrer" social>
              <LinkText>Facebook</LinkText>
            </SocialLink>
            <SocialLink href="https://twitter.com/AvproJets" target="_blank" rel="noopener noreferrer" social>
              <LinkText>X</LinkText>
            </SocialLink>
            <SocialLink href="https://www.instagram.com/avprojets/" target="_blank" rel="noopener noreferrer" social>
              <LinkText>Instagram</LinkText>
            </SocialLink>
          </SocialLinks>
        </NavContent>
        <NavToggle onClick={toggleNav}>
          <NavToggleBox>
            <NavToggleInner menuActive={menuActive} />
          </NavToggleBox>
        </NavToggle>
      </NavBar>
    );
  }
  return null;
};

export default withRouter(ScrolledNav);

const NavBar = styled.div`
  height: ${vars.mobileNavBar};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 10;
  align-items: center;
  background: ${colors.mineShaft};
  justify-content: space-between;
  padding: 0 ${vars.gridGap * 2.5}px;
  transform: translateY(0);
  background: ${mediaQuery.lg};
  transition: 600ms ease-out;
  will-change: transform;

  @media ${mediaQuery.lg} {
    transition: 600ms ease;
    transform: translateY(-100%);
  }

  ${(props) =>
    props.scrolledNav &&
    css`
      transform: translateY(0);

      @media ${mediaQuery.lg} {
        transform: translateY(0);
      }
    `}
`;

const LogoBox = styled(link)`
  width: auto;
  height: 24px;

  svg {
    width: auto;
    height: 100%;
    object-fit: contain;
    fill: ${colors.white};
    pointer-events: none;
  }
`;

const NavContent = styled.nav`
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  width: 275px;
  padding: ${vars.gridGap * 5}px 0 ${vars.gridGap * 2}px;
  transform: translateX(100%);
  transition: 300ms ease;
  will-change: transform;

  ${(props) =>
    props.menuActive &&
    css`
      transform: translateX(0);
    `}
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContactLinks = styled(Links)`
  margin-top: auto;
`;

const SocialLinks = styled(Links)`
  margin-top: ${vars.gridGap * 2}px;
  position: relative;
  padding-top: ${vars.gridGap * 2}px;

  &::before {
    height: 1px;
    background: ${colors.alto};
    width: calc(100% - ${vars.gridGap * 4}px);
    display: block;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
`;

const Link = styled(link)`
  position: relative;
  text-decoration: none;
  padding: ${vars.gridGap * 0.75}px ${vars.gridGap * 2}px;

  &:hover {
    box-shadow: inset 6px 0 0 ${colors.tea};
  }

  ${(props) =>
    props.params.isActive &&
    css`
      box-shadow: inset 6px 0 0 ${colors.makara};

      ${LinkText} {
        color: ${colors.makara};
      }
    `}
`;

const LinkText = styled.p`
  ${font({
    name: "realist",
    size: "14px",
    weight: 600,
    color: colors.black,
    letterSpacing: "2px",
    transform: "uppercase",
  })};
  margin: 0;
  transition: transform 200ms;
`;

const ContactLink = styled.a`
  position: relative;
  text-decoration: none;
  padding: ${vars.gridGap * 0.75}px ${vars.gridGap * 2}px;

  ${LinkText} {
    font-size: 18px;
    text-transform: none;
    color: ${colors.makara};
    letter-spacing: 1px;
  }

  &:hover {
    ${LinkText} {
      text-decoration: underline;
    }
  }
`;

const SocialLink = styled.a`
  position: relative;
  text-decoration: none;
  padding: ${vars.gridGap * 0.75}px ${vars.gridGap * 2}px;

  ${LinkText} {
    font-size: 11px;
    color: ${colors.makara};
    letter-spacing: 1px;
  }

  &:hover {
    ${LinkText} {
      text-decoration: underline;
    }
  }
`;

const NavToggle = styled.button`
  box-sizing: content-box;
  padding: ${vars.gridGap / 4}px;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:focus {
    outline-offset: -4px;
  }
`;

const NavToggleBox = styled.div`
  position: relative;
  display: inline-block;
  align-self: center;
  width: 22px;
  height: 14px;
`;

const NavToggleInner = styled.span`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22px;
  height: 2px;
  background-color: ${colors.white};
  transition: 150ms ease;

  &::before,
  &::after {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.white};
    backface-visibility: hidden;
    transition: 150ms ease;
    content: "";
  }

  &::before {
    top: 7px; /* middle line */
  }

  &::after {
    top: 14px; /* bottom line */
  }

  /* Menu Active */
  ${(props) =>
    props.menuActive &&
    css`
      top: 7px;
      transform: rotate(45deg);
      background: ${colors.blueGem};

      &::before,
      &::after {
        background: ${colors.blueGem};
        top: 0;
      }

      &::after {
        transform: rotate(-90deg); // offset parent rotate + self rotate
      }

      &::before {
        transform: rotate(-45deg); // offset parent rotate
        opacity: 0; // fade out
      }
    `}
`;

const NavScrim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(30, 30, 30, 0.9);
  visibility: hidden;
  opacity: 0;
  transition: 250ms;
  height: 100vh;
  width: 100vw;
  will-change: opacity;

  ${(props) =>
    props.menuActive &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
