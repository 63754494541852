import React, { useState, useEffect } from "react";

import { rgba } from "polished";
import queryString from "query-string";
import styled, { css } from "styled-components/macro";

import { vars, colors, font, mediaQuery } from "../../../helpers/styles";
import { updateURL } from "../../../hooks";
import { Icon, Search as SearchIcon } from "../../Icons";

const InventorySearch = (props) => {
  const g = queryString.parse(props.location.search);

  const [search, setSearch] = useState(g.name || "");

  const newURL = updateURL(g, "name", props.location.search, search.toLowerCase());

  useEffect(() => {
    props.history.push(`/${newURL}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <SearchContainer onSubmit={handleSubmit} scrolledNav={props.scrolledNav}>
      <Search
        onChange={(e) => {
          props.scrollToMain();
          setSearch(e.target.value);
        }}
        type="text"
        value={search}
        placeholder="Search"
      />
      <SearchIconBox>
        <SearchIcon />
      </SearchIconBox>
    </SearchContainer>
  );
};

export default InventorySearch;

const SearchContainer = styled.form`
  display: flex;
  align-items: center;
  position: sticky;
  top: ${vars.mobileNavBar};
  z-index: 5;
  transition: 600ms ease;
  will-change: top;

  @media ${mediaQuery.lg} {
    top: 0;

    ${(props) =>
      props.scrolledNav &&
      css`
        top: 65px;
      `}
  }
`;

const Search = styled.input`
  ${font({
    name: "realist",
    size: "18px",
    weight: 400,
    color: colors.mineShaft,
  })};
  background: white;
  display: flex;
  align-items: center;
  height: 65px;
  padding: ${vars.gridGap}px ${vars.gridGap * 3}px ${vars.gridGap}px
    calc(${vars.gridGap * 3}px + 20px + ${vars.gridGap}px);
  border-radius: 0;
  border: none;
  width: 100%;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  transition: 200ms;

  &::placeholder {
    color: ${rgba(colors.mineShaft, 0.3)};
    transition: 200ms;
  }

  &:hover {
    &::placeholder {
      color: ${rgba(colors.mineShaft, 0.5)};
    }
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px ${colors.makara};

    &::placeholder {
      color: ${rgba(colors.mineShaft, 0.5)};
    }
  }

  @media ${mediaQuery.lg} {
    height: 80px;
  }
`;

const SearchIconBox = styled(Icon)`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: ${vars.gridGap * 3}px;
  opacity: 0.5;
  fill: ${colors.mineShaft};
  transition: 200ms;

  ${Search}:focus + & {
    opacity: 1;
  }

  ${Search}:hover + & {
    opacity: 1;
  }
`;
