import React from "react";

import styled from "styled-components/macro";

import CompanyCTA from "./components/CompanyCTA";
import CompanyHero from "./components/CompanyHero";
import CompanyIntegrity from "./components/CompanyIntegrity";
import CompanyIntro from "./components/CompanyIntro";
import CompanyJourney from "./components/CompanyJourney";
import CompanyKnowledge from "./components/CompanyKnowledge";
import CompanyPerformance from "./components/CompanyPerformance";
import Footer from "../Footer";

const Company = (props) => {
  return (
    <>
      <CompanyHero toggleScrolledNav={props.toggleScrolledNav} />
      <Content>
        <CompanyIntro />
        <CompanyKnowledge />
        <CompanyPerformance />
        <CompanyIntegrity />
        <CompanyJourney />
      </Content>
      <CompanyCTA />
      <Footer />
    </>
  );
};

export default Company;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
