import React from "react";

import styled from "styled-components/macro";

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: auto;
    height: 100%;
  }
`;

export const Logo = () => (
  <svg viewBox="0 0 134 35.42">
    <path d="M40.17,25.34c2,.09,4.79,1.91,4.79,1.91a32.82,32.82,0,0,0-4.77-3.07L41.26,0H37.65L25.47,17.67C9.63,10.67,0,19.27,0,19.27l5.09,3c8.74-6.51,19.8-3.39,19.8-3.39L14.67,34.26H11.2v1H21.68l0-1H16.73l10-14.7c3.75.62,8.44,3.32,8.44,3.32l-.58,11.33H30.69v1.12h11.6V34H40ZM35.23,22a45.75,45.75,0,0,0-7.75-3.34L35.7,6Z" />
    <path d="M89.53,11.24c-.47-3.69-4.6-4.36-10.4-4.36H63.61V8l2.9-.12L51.27,31.21l1.5-23.34h3.14v-1H46.62V7.93h1.52L47.08,35.28h3.18L68.52,8.11h2.75L61.82,34.19h-2.9v1.09h9.77V34.14l-2.85,0,4.38-12a29.25,29.25,0,0,0,11.44-.87C86,20.06,90,14.94,89.53,11.24Zm-4.9,2.7c-1.19,2.77-2.68,5.31-6.25,6.17a33.63,33.63,0,0,1-7.5.33L75.42,7.92h5.43c3.21,0,5.27,2.56,3.78,6Z" />
    <path d="M105,7.08H90.86v.8H93.2L83.78,34.16h-2.5v1.07h8.59V34.1h-2l4.4-12.17,6.93,13.35h6.18V34.15h-1.57L97.29,21.73c7.57-.25,13.75-5.79,14-9.8S109.66,7.08,105,7.08ZM106.43,14a9.11,9.11,0,0,1-6.56,5.84,28.7,28.7,0,0,1-7.05.26l4.4-12.12h5.43c3.21,0,5.26,2.55,3.78,6Z" />
    <path d="M126.58,6.31c-6.45,0-14.12,6.5-17.13,14.54s-.24,14.56,6.2,14.57,14.11-6.5,17.13-14.54S133,6.32,126.58,6.31ZM129.35,21c-2.94,7.6-8.94,13.75-13.38,13.73s-5.66-6.17-2.71-13.77S122.2,7.21,126.64,7.22,132.3,13.4,129.35,21Z" />
  </svg>
);

export const LogoSmall = () => (
  <svg viewBox="0 0 176.2 138.28">
    <path d="M157.41,99.31c7.86.35,18.79,7.49,18.79,7.49a130.83,130.83,0,0,0-18.71-12.06L161.69,0H147.53L99.81,69.23C37.81,41.8,0,75.51,0,75.51L19.93,87.22C54.19,61.7,97.54,73.94,97.54,73.94l-40,60.3H43.88v3.89H85l-.13-4H65.55l39.28-57.62c14.69,2.44,33.09,13,33.09,13l-2.28,44.4H120.26v4.37h45.45v-4.89h-8.94ZM138,86.07S120.31,76.43,107.71,73l32.2-49.47Z" />
  </svg>
);

export const Alert = () => (
  <svg viewBox="0 0 20 20">
    <path d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm1,15H9V13h2Zm0-4H9V5h2Z" />
  </svg>
);

export const ArrowDown = () => (
  <svg viewBox="0 0 16.03 16.03">
    <path d="M16,8,8,16,0,8,1.45,6.61,7,12.19V0H9V12.19l5.62-5.58Z" />
  </svg>
);

export const ArrowRight = () => (
  <svg viewBox="0 0 16 16">
    <path d="M8,0l8,8L8,16,6.61,14.55,12.19,9H0V7H12.19L6.61,1.38Z" />
  </svg>
);

export const Brochure = () => (
  <svg viewBox="0 0 27 23.38">
    <path d="M13.5,23.38a1,1,0,0,1-.81-.41,7.24,7.24,0,0,0-6-3,7.49,7.49,0,0,0-5.05,1.83A1,1,0,0,1,.58,22a1,1,0,0,1-.58-.9v-18a1,1,0,0,1,.35-.77A9.64,9.64,0,0,1,6.69,0,9.18,9.18,0,0,1,13.5,2.82,9.3,9.3,0,0,1,20.34,0a9.59,9.59,0,0,1,6.31,2.29,1,1,0,0,1,.35.77v18a1,1,0,0,1-1.65.76,7.56,7.56,0,0,0-5-1.84h-.23a7.24,7.24,0,0,0-5.86,3A1,1,0,0,1,13.5,23.38ZM6.89,18a9.22,9.22,0,0,1,6.61,2.82A9.28,9.28,0,0,1,20.34,18,9.86,9.86,0,0,1,25,19.19V3.53A7.77,7.77,0,0,0,20.33,2a7.31,7.31,0,0,0-6,3,1,1,0,0,1-1.62,0,7.21,7.21,0,0,0-6-3A7.69,7.69,0,0,0,2,3.53V19.18A10,10,0,0,1,6.69,18Z" />
  </svg>
);

export const Video = () => (
  <svg viewBox="0 0 30 30">
    <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,2A13,13,0,1,0,28,15,13,13,0,0,0,15,2Z" />
    <path d="M11,21.82V8.21l10.89,6.71Zm2-10v6.39l5.11-3.24Z" />
  </svg>
);

export const Gallery = () => (
  <svg viewBox="0 0 32 30">
    <path d="M32,30H0V4H32ZM2,28H30V6H2Z" />
    <rect x="6" width="20" height="2" />
    <path d="M28.82,25H5.43L20.2,11.46ZM10.57,23H25.18L19.8,14.54Z" />
    <path d="M8.5,16A3.5,3.5,0,1,1,12,12.5,3.5,3.5,0,0,1,8.5,16Zm0-5A1.5,1.5,0,1,0,10,12.5,1.5,1.5,0,0,0,8.5,11Z" />
  </svg>
);

export const VirtualTour = () => (
  <svg viewBox="0 0 32 32">
    <path d="M16,7.91,8,11.34V21.66l8,3.43,8-3.43V11.34ZM20.46,12,16,13.91,11.54,12,16,10.09ZM10,13.52l5,2.14v6.82l-5-2.14Zm7,9V15.66l5-2.14v6.82Z" />
    <path d="M2,17H0V16A16,16,0,0,1,16,0h4.41L15,5.41,13.59,4l2-2A14,14,0,0,0,2,16Z" />
    <path d="M16,32H11.59L17,26.59,18.41,28l-2,2A14,14,0,0,0,30,16V15h2v1A16,16,0,0,1,16,32Z" />
  </svg>
);

export const Avionics = () => (
  <svg viewBox="0 0 40.02 25.3">
    <path d="M40,9.36V5.91h0V2.29a2.14,2.14,0,0,0,0-.44,2,2,0,0,0-.79-1.4A2.06,2.06,0,0,0,37.74,0H33.11V1.24h-1.4a2.09,2.09,0,0,0-2.27,1.82,2.34,2.34,0,0,0,0,.46V5.86a2.07,2.07,0,0,0,.44,1.48,2.13,2.13,0,0,0,1.41.79h1.83V9.36h1.23V12a5.24,5.24,0,0,1-5.13,5.15H26L26.53,12V10.82c0-3.8-3-7.13-6.51-7.13S13.5,7,13.5,10.82V12L14,17.17H10.84A5.24,5.24,0,0,1,5.69,12V9.36H6.92V8.13H8.75A2.11,2.11,0,0,0,10.6,5.86V3.51a2.17,2.17,0,0,0,0-.44A2.08,2.08,0,0,0,8.32,1.24H6.92V0H2.29A2.06,2.06,0,0,0,.82.44,2.1,2.1,0,0,0,0,1.84a1.7,1.7,0,0,0,0,.45L0,9.36H0v2.73a11.26,11.26,0,0,0,10.8,10.76h3.76l.24,2.45H25.21l.24-2.45h3.8A11.25,11.25,0,0,0,40,12V9.36Zm-6.9-3.23h-1.6c-.05,0-.09-.06-.09-.11V3.32l.11-.09h1.58ZM6.92,3.24H8.49c.06,0,.11,0,.11.11V6a.09.09,0,0,1-.08.11H6.92ZM2.12,2h2.8V7.36H2V5.91H2V2.09S2.05,2,2.12,2Zm8.75,18.85A9.25,9.25,0,0,1,2,12V9.36H3.69v2.7a7.24,7.24,0,0,0,7.13,7.11h3.39l.17,1.68ZM23.4,23.3H16.63L15.5,12V10.82c0-2.42,1.93-5.13,4.52-5.13s4.51,2.71,4.51,5.13v1.12ZM35.11,2h2.8a.15.15,0,0,1,.11.12V7.36h-2.9ZM38,12a9.25,9.25,0,0,1-8.8,8.86H25.65l.17-1.68h3.41A7.24,7.24,0,0,0,36.34,12V9.36H38Z" />
    <path d="M20,8a3.45,3.45,0,1,0,3.46,3.45A3.46,3.46,0,0,0,20,8Zm0,4.9a1.45,1.45,0,1,1,1.46-1.45A1.45,1.45,0,0,1,20,12.88Z" />
  </svg>
);

export const ClubSeat = () => (
  <svg viewBox="0 0 32 32">
    <path d="M32,13H23v7H9V13H0V29H4v3H6V29H26v3h2V29h4ZM30,27H2V15H7v7H25V15h5Z" />
    <path d="M28,11H26V4a2,2,0,0,0-2-2H8A2,2,0,0,0,6,4v7H4V4A4,4,0,0,1,8,0H24a4,4,0,0,1,4,4Z" />
  </svg>
);

export const Fwd = () => (
  <svg viewBox="0 0 37 37">
    <g>
      <rect x="6" y="2" width="2" height="3" />
      <rect x="3" y="1" width="2" height="4" />
      <rect x="9" width="2" height="5" />
      <g>
        <path d="M13,6H1v4a6,6,0,0,0,11.65,2H13a3,3,0,0,0,3-3V6ZM7,14a4,4,0,0,1-4-4V8h8v2A4,4,0,0,1,7,14Zm7-5a1,1,0,0,1-1,1V8h1Z" />
        <polygon points="0 18 15 18 15 16 7 16 0 16 0 18" />
      </g>
      <path d="M32.89,13.15l-.66-4.57a3,3,0,0,0-1.23-2V3a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2V6.57a3,3,0,0,0-1.23,2l-.66,4.57A3,3,0,0,0,20,16v2H35V16A3,3,0,0,0,32.89,13.15ZM25.73,8h3.54a1,1,0,0,1,1,.86L30.85,13h-6.7l.59-4.14A1,1,0,0,1,25.73,8ZM26,3h3V5H26ZM22,16a1,1,0,0,1,1-1h9a1,1,0,0,1,1,1Z" />
      <path d="M13,29V23a2,2,0,0,0-2-2H4a2,2,0,0,0-2,2v6H0v1a5,5,0,0,0,4,4.9V37h7V34.9A5,5,0,0,0,15,30V29ZM4,23h7v6H4Zm6,10H9v2H6V33H5a3,3,0,0,1-2.83-2H12.83A3,3,0,0,1,10,33Z" />
      <path d="M30,33H22V26h8Zm-6-2h4V28H24Z" />
      <path d="M37,36H19V23H37ZM21,34H35V25H21Z" />
      <circle cx="32.5" cy="27.5" r="1" />
      <circle cx="32.5" cy="31.5" r="1" />
    </g>
  </svg>
);

export const Coffee = () => (
  <svg viewBox="0 0 29 32">
    <rect x="13" width="2" height="5" />
    <rect x="7" y="2" width="2" height="5" />
    <rect x="19" y="2" width="2" height="5" />
    <path d="M24,10H4v8a10,10,0,0,0,19.8,2H25a4,4,0,0,0,4-4V10Zm-2,8A8,8,0,0,1,6,18V12H22Zm5-2a2,2,0,0,1-2,2H24V12h3Z" />
    <rect y="30" width="29" height="2" />
  </svg>
);

export const Jumpseat = () => (
  <svg viewBox="0 0 30 32">
    <path d="M27,24H25.88L24.34,12.47a4,4,0,0,0-4-3.47H16V7h5V2a2,2,0,0,0-2-2H11A2,2,0,0,0,9,2V7h5V9H9.63a4,4,0,0,0-4,3.47L4.12,24H3a3,3,0,0,0-3,3v5H30V27A3,3,0,0,0,27,24ZM11,2h8V5H11ZM7.64,12.73a2,2,0,0,1,2-1.73H20.37a2,2,0,0,1,2,1.73L23.86,24H6.14ZM28,30H2V27a1,1,0,0,1,1-1H27a1,1,0,0,1,1,1Z" />
  </svg>
);

export const Lavatory = () => (
  <svg viewBox="0 0 28 32">
    <path d="M24,14V4a3.94,3.94,0,0,0-3.86-4H8A3.88,3.88,0,0,0,5.21,1.11,3.92,3.92,0,0,0,4,3.89V14H0v4a8,8,0,0,0,8,8H9v6H19V26h1a8,8,0,0,0,8-7.88V14ZM6,3.91a1.94,1.94,0,0,1,.6-1.37A2,2,0,0,1,8,2H20.09A1.95,1.95,0,0,1,22,4V14H6ZM2,16H26v2H2Zm22.23,6.28A6.06,6.06,0,0,1,20,24H17v6H11V24H7.92a6,6,0,0,1-5.58-4H25.67A6,6,0,0,1,24.23,22.28Z" />
  </svg>
);

export const Luggage = () => (
  <svg viewBox="0 0 26 32">
    <rect x="9" y="18" width="8" height="2" />
    <path d="M23,4H18V0H8V4H3A3,3,0,0,0,0,7V30H2v2H4V30H22v2h2V30h2V7A3,3,0,0,0,23,4ZM10,2h6V4H10ZM24,28H2V16H24ZM2,14V7A1,1,0,0,1,3,6H23a1,1,0,0,1,1,1v7Z" />
  </svg>
);

export const Passenger = () => (
  <svg viewBox="0 0 17 42.8">
    <path d="M8.5,12.05h0a6,6,0,1,1,6-6A6,6,0,0,1,8.5,12.05Zm0-10a4,4,0,1,0,4,4A4,4,0,0,0,8.5,2Z" />
    <path d="M13.2,42.8H3.8V30.3H0V19.9a4.8,4.8,0,0,1,4.8-4.8h7.4A4.8,4.8,0,0,1,17,19.9V30.3H13.2Zm-7.4-2h5.4V28.3H15V19.9a2.81,2.81,0,0,0-2.8-2.8H4.8A2.81,2.81,0,0,0,2,19.9v8.4H5.8Z" />
  </svg>
);

export const Bed = () => (
  <svg viewBox="0 0 50.5 39.48">
    <path d="M12,17a5,5,0,1,1,5-5A5,5,0,0,1,12,17Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,12,9Z" />
    <path d="M50.5,17.43H22V6.61H45.09A5.42,5.42,0,0,1,50.5,12ZM24,15.43H48.5V12a3.41,3.41,0,0,0-3.41-3.41H24Z" />
    <polygon points="2 22.05 2 0 0 0 0 22.05 0 24.05 0 31 0 33 0 39 2 39 2 33 48.5 33 48.5 39.48 50.5 39.48 50.5 22.05 2 22.05" />
  </svg>
);

export const ChevronRight = () => (
  <svg viewBox="0 0 7.41 12">
    <path d="M1.41,0l6,6-6,6L0,10.59,4.59,6,0,1.41Z" />
  </svg>
);

export const Entertainment = () => (
  <svg viewBox="0 0 32 28">
    <rect x="7" y="26" width="18" height="2" />
    <path d="M32,23H0V0H32ZM2,21H30V2H2Z" />
    <path d="M13,17.87V6.13L21.8,12Zm2-8v4.26L18.2,12Z" />
  </svg>
);

export const Voice = () => (
  <svg viewBox="0 0 30 30">
    <path d="M30,14H28V13A11,11,0,0,0,17,2H16V0h1A13,13,0,0,1,30,13Z" />
    <path d="M24,14H22V13a5,5,0,0,0-5-5H16V6h1a7,7,0,0,1,7,7Z" />
    <path d="M25.77,30a2.82,2.82,0,0,1-.41,0A29.89,29.89,0,0,1,0,4.64,3,3,0,0,1,2.2,1.38L7.16.1a2.92,2.92,0,0,1,3.42,1.65l2.6,5.85a3,3,0,0,1-.85,3.49l-2.16,1.73a23.09,23.09,0,0,0,7,7l1.73-2.16a2.93,2.93,0,0,1,3.49-.84l5.85,2.59a2.94,2.94,0,0,1,1.66,3.43L28.62,27.8A3,3,0,0,1,25.77,30ZM7.9,2a1,1,0,0,0-.24,0L2.71,3.32A.94.94,0,0,0,2,4.37,27.9,27.9,0,0,0,25.64,28a1,1,0,0,0,1.05-.71L28,22.34a1,1,0,0,0-.53-1.09l-5.85-2.6a1,1,0,0,0-1.12.27l-2.82,3.53L16.9,22A25.18,25.18,0,0,1,8,13.1l-.44-.75,3.53-2.82a.93.93,0,0,0,.27-1.11L8.75,2.56A.92.92,0,0,0,7.9,2Z" />
  </svg>
);

export const Internet = () => (
  <svg viewBox="0 0 30.03 24">
    <path d="M15,24a4,4,0,1,1,4-4A4,4,0,0,1,15,24Zm0-6a2,2,0,1,0,2,2A2,2,0,0,0,15,18Z" />
    <path d="M22.81,13.61l-.71-.7A9.87,9.87,0,0,0,15,10h0a10,10,0,0,0-7.08,2.91l-.71.7L5.8,12.19l.71-.7A12,12,0,0,1,15,8h.13a11.9,11.9,0,0,1,8.4,3.5l.71.7Z" />
    <path d="M28.63,8l-.72-.7a18.32,18.32,0,0,0-25.8,0L1.4,8,0,6.59l.71-.7a20.3,20.3,0,0,1,28.6,0l.72.7Z" />
  </svg>
);

export const Search = () => (
  <svg viewBox="0 0 22.41 22.41">
    <path d="M22.41,21,16,14.61A8.91,8.91,0,0,0,18,9a9,9,0,1,0-3.39,7L21,22.41ZM2,9a7,7,0,1,1,7,7A7,7,0,0,1,2,9Z" />
  </svg>
);

export const Filter = () => (
  <svg viewBox="0 0 18 12">
    <path d="M7,12h4V10H7ZM0,0V2H18V0ZM3,7H15V5H3Z" />
  </svg>
);

export const Close = () => (
  <svg viewBox="0 0 24 24">
    <path d="M 4.7070312 3.2929688 L 3.2929688 4.7070312 L 10.585938 12 L 3.2929688 19.292969 L 4.7070312 20.707031 L 12 13.414062 L 19.292969 20.707031 L 20.707031 19.292969 L 13.414062 12 L 20.707031 4.7070312 L 19.292969 3.2929688 L 12 10.585938 L 4.7070312 3.2929688 z" />
  </svg>
);
