import React from "react";

import { Switch, Route } from "react-router";

import Company from "./components/company/Company";
import Contact from "./components/contact/Contact";
import FinancialAdvisoryServices from "./components/financial-advisory-services/FinancialAdvisoryServices";
import Inventory from "./components/inventory/Inventory";
import NotFound from "./components/NotFound";
import PlaneDetails from "./components/PlaneDetails";
import Services from "./components/services/Services";

export default function (props) {
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(routerProps) => (
          <Inventory
            {...routerProps}
            planes={props.planes}
            scrolledNav={props.scrolledNav}
            toggleScrolledNav={props.toggleScrolledNav}
            toggleLockScroll={props.toggleLockScroll}
            onSort={props.onSort}
            introAnimation={props.introAnimation}
            setIntroAnimation={props.setIntroAnimation}
          />
        )}
      />
      <Route path="/company" exact render={() => <Company toggleScrolledNav={props.toggleScrolledNav} />} />
      <Route path="/services" exact render={() => <Services toggleScrolledNav={props.toggleScrolledNav} />} />
      <Route
        path="/financial-advisory-services"
        exact
        render={() => <FinancialAdvisoryServices toggleScrolledNav={props.toggleScrolledNav} />}
      />
      <Route path="/contact" exact render={() => <Contact toggleScrolledNav={props.toggleScrolledNav} />} />
      <Route
        path="/:aircraft"
        render={(routerProps) => (
          <PlaneDetails
            {...routerProps}
            planes={props.planes}
            toggleScrolledNav={props.toggleScrolledNav}
            toggleLockScroll={props.toggleLockScroll}
          />
        )}
      />
      <Route component={NotFound} />
    </Switch>
  );
}
