import * as React from "react";

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

type Breakpoint = keyof typeof breakpoints;

export interface WithWidthProps {
  windowWidth: number;
  isUp: (size: Breakpoint) => boolean;
  isDown: (size: Breakpoint) => boolean;
}

export const withWidth = (Component: React.ComponentType<any>) => {
  class WithWidth extends React.Component<any> {
    public readonly state = {
      innerWidth: window.innerWidth,
    };

    componentDidMount() {
      this.storeWindowSize();
      window.addEventListener("resize", this.storeWindowSize);
    }

    componentWillUnmount(): void {
      window.removeEventListener("resize", this.storeWindowSize);
    }

    private readonly storeWindowSize = () => {
      this.setState({
        innerWidth: window.innerWidth,
      });
    };

    private readonly isUp = (size: Breakpoint) => {
      return this.state.innerWidth >= breakpoints[size];
    };

    private readonly isDown = (size: Breakpoint) => {
      return this.state.innerWidth <= breakpoints[size];
    };

    render() {
      const { innerWidth } = this.state;

      return <Component {...this.props} windowWidth={innerWidth} isUp={this.isUp} isDown={this.isDown} />;
    }
  }
  return WithWidth;
};
