import React from "react";

import styled from "styled-components/macro";

import AvproFinancingSolutions from "./components/AvproFinancingSolutions";
import ShouldIConsiderNewLender from "./components/ShouldIConsiderNewLender";
import StretchAmortization from "./components/StretchAmortization";
import TableOfContents from "./components/TableOfContents";
import TheAvproFinancialAdvantage from "./components/TheAvproFinancialAdvantage";
import TheFinancialAdvisoryProcess from "./components/TheFinancialAdvisoryProcess";
import Footer from "../Footer";

const FinancialAdvisoryServices = () => {
  return (
    <>
      <Content>
        <TableOfContents />
        <AvproFinancingSolutions />
        <TheAvproFinancialAdvantage />
        <ShouldIConsiderNewLender />
        <TheFinancialAdvisoryProcess />
        <StretchAmortization />
      </Content>
      <Footer />
    </>
  );
};

export default FinancialAdvisoryServices;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
