import * as React from "react";

import styled from "styled-components";

import type { WithWidthProps } from "../../../hoc/withWidth";
import { breakpoints, withWidth } from "../../../hoc/withWidth";

const headerHeightPixels = 180;

const Content = styled("div")`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 8rem;
  padding-right: 8rem;

  h4 {
    font-family: "Selawik Semibold";
    color: rgb(30, 71, 112);
    font-size: 16pt;
  }

  p {
    font-family: "source-sans-pro";
    font-size: 14pt;
  }

  > div {
    padding: 1rem;
  }

  @media (min-width: ${breakpoints.lg}px) {
    > div {
      max-width: 400px;
    }
  }
`;

const TheAvproFinancialAdvantage = (props: WithWidthProps) => {
  // const contentWidthPercent = props.isUp("md") ? 80 : 100;
  // const contentHeightPixels = props.isUp("md") ? 750 : props.isUp("sm") ? 1000 : 1300;
  return (
    <div
      id="the-avpro-financial-advantage"
      style={{
        position: "relative",
        minHeight: "850px",
        fontFamily: "source-sans-pro",
        background: "url('../images/financial-services/Page 3 Image.jpg')",
        backgroundSize: "cover",
        display: "flex",
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            color: "white",
            textAlign: "center",
            fontSize: props.isUp("md") ? "36pt" : "24pt",
          }}
        >
          <h2 style={{ fontFamily: "Selawik Semibold", margin: 0, padding: "2rem", paddingTop: "4rem" }}>
            The Avpro Financial Advantage
          </h2>
        </div>
        <div
          style={{
            flex: props.isUp("lg") ? undefined : 1,
            height: props.isUp("lg") ? "850px" : undefined,
            background: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Content>
            <div style={{ fontSize: "13pt" }}>
              <p style={{ fontStyle: "italic " }}>
                Our knowledge of the capital markets provides you an in troduction to lenders who don’t call on you but
                want your business and offer competitive terms.
              </p>
            </div>
            <div>
              <h4>ACCESS TO CAPITAL</h4>
              <p>
                Your primary lender reviews your financial profile at least semi-annually and adjusts their internal
                lending capacity. In any economic environment, multiple sources of lending capital are the keys to
                preserving and growing your business, while maintaining capacity with your primary lender.
              </p>
            </div>
            <div>
              <h4>MATCHING FINANCING COSTS TO YOUR OBJECTIVES</h4>
              <p>
                We listen and help guide you through how the financing costs may change based on your needs to a) limit
                credit exposure; b) reduce payment costs; c) mitigate financial covenants; and d) how each will change
                based on your structural flexibility.
              </p>
            </div>
            <div>
              <h4>EFFICIENCY</h4>
              <p>
                Our knowledge of each lender’s preferred business, terms, and pricing provides you access to lenders who
                are as competitive, or more competitive, than your primary lender and speeds the financing process.
              </p>
            </div>
            <div>
              <h4>LENDER COMPETITION</h4>
              <p>
                Our process of only selecting the top three to five lenders who match your lending profile, assures
                achieving the best terms available in the marketplace, while avoiding a broker-like wide distribution.
                Each lender knows our goals (which are your goals) our knowledge of the markets and will put their best
                bid forward.
              </p>
            </div>
          </Content>
        </div>
      </div>
      {props.isUp("lg") && (
        <>
          <div
            style={{
              marginTop: `${headerHeightPixels + 10}px`,
              width: "20px",
              background: "rgb(60, 134, 163, 0.5)",
            }}
          />
          <div
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "80pt",
              margin: "auto",
              paddingLeft: "2rem",
              paddingRight: "2rem",
            }}
          >
            <h2
              style={{
                // transform: "translateY(-50%) rotate(-90deg)",
                writingMode: "vertical-lr",
                transform: "rotate(180deg)",
                // top: "50%",
                position: "relative",
                fontFamily: "source-sans-pro",
                fontWeight: 200,
                margin: 0,
              }}
            >
              ADVANTAGE
            </h2>
          </div>
        </>
      )}
    </div>
  );
};

export default withWidth(TheAvproFinancialAdvantage);
