// updates the url query params to either add parameter or update it
export function updateURL(params, param, currentURL, newString) {
  if (newString === "") {
    return removeParamFromURL(param, params);
  }
  params[param] = newString;
  return generateURL(params);
}

function generateURL(obj) {
  let url = "";
  Object.keys(obj).forEach((key) => {
    url = url === "" ? `?${key}=${obj[key]}` : `${url}&${key}=${obj[key]}`;
  });
  return url;
}

// updates the url to remove a specific query param (aka clearing your search)
export function removeParamFromURL(param, params) {
  if (Array.isArray(param)) {
    param.forEach((i) => delete params[i]);
    delete params[param];
  } else {
    delete params[param];
  }
  return generateURL(params);
}

export function generateLabels(str) {
  const arr = str.split(",");
  const ret = arr.map((i) => {
    return { label: i, value: i };
  });
  return ret;
}

function check(param, params, planeObj) {
  const vals = params[param].toLowerCase().split(","); // split the values for that parameter from the url into an array
  return vals.some((val) => {
    // make sure at least ONE value from the parameters matches the planes value for that parameter
    if (planeObj[param]) {
      return planeObj[param].toString().toLowerCase().includes(val);
    }
    return false;
  });
}

export function filterOptions(param, params, planeObj) {
  const checkParam = (val) => {
    if (val === parseInt(val)) {
      return val;
    }
    const cleanedVal = parseInt(
      val.replace(/\$/g, "").replace("isGreater-", "").replace("isLess-", "").replace(/,/g, ""),
    );
    if (isNaN(cleanedVal)) {
      return 1000000000;
    }
    return cleanedVal;
  };

  if (params[param].includes("isLess")) {
    return checkParam(planeObj[param]) < checkParam(params[param]);
  }
  if (params[param].includes("isGreater")) {
    return checkParam(planeObj[param]) > checkParam(params[param]);
  }
  if (params[param].includes("-")) {
    const splitRange = params[param].split("-");
    return (
      checkParam(planeObj[param]) >= parseInt(splitRange[0]) && checkParam(planeObj[param]) <= parseInt(splitRange[1])
    );
  }
}

export function filterAllParams(params, planeObj) {
  // name is treated differently so we don't want to use it in the general filter function
  const keys = Object.keys(params)
    .filter((i) => i !== "name")
    .filter((i) => i !== "year_model")
    .filter((i) => i !== "airframe_total_time")
    .filter((i) => i !== "price")
    .filter((i) => i !== "flight_range");

  // make sure every parameter is passed by the plane if we want to return true
  return keys.every((key) => {
    return check(key, params, planeObj); // check that at least one of the options for each parameter matches the planes value for that parameter
  });
}

/**
 * filter slider function: takes in a plane, the relevant parameter, and the min and max values from the slider
 * Returns whether the planes value for the given category (param) falls between the min and max values
 * This function should be called on every slider filter
 *  */
export function filterSlider(param, minVal, maxVal, planeObj) {
  const min = parseInt(minVal);
  const max = parseInt(maxVal);

  const p = planeObj[param].replace(/,/g, "");
  return parseInt(p) >= min && parseInt(p) <= max;
}

// e.g. -- filterLessThan('Range', 2000, plane)
export function filterLessThan(param, val, planeObj) {
  const p = planeObj[param].replace(/,/g, "");
  return parseInt(p) < parseInt(val);
}

// e.g. -- filterGreaterThan('Range', 2000, plane)
export function filterGreaterThan(param, val, planeObj) {
  const p = planeObj[param].replace(/,/g, "");
  return parseInt(p) > parseInt(val);
}

export function filterGlobal(searchVal, planeObj) {
  const keys = Object.keys(planeObj); // get all key fields of object
  return keys.some((item) => {
    // return true if any field value matches query params
    if ((item === "model" || item === "manufacturer" || item === "serial_number") && planeObj[item]) {
      // make sure the value of the field isn't null
      return planeObj[item]
        .toString() // convert to a string in case the object value is a number (query param val will always be a string)
        .toLowerCase()
        .includes(searchVal); // see if query param val is some substring of the object value
    }
    // if value is null from the plane auto return false
    return false;
  });
}

// pass in object of all sliders to this
export function filterSliders(planeObj, allSliders) {
  const categories = Object.keys(allSliders);
  return categories.every((key) => {
    const min = allSliders[key][0];
    const max = allSliders[key][1];
    const val = parseInt(planeObj[key]);
    return val >= min && val <= max; // make sure val is between min and max
  });
}
