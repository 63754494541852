import React from "react";

import styled from "styled-components/macro";

import { vars, colors, mediaQuery, SectionTitle as sectionTitle, SectionText } from "../../../helpers/styles";
import { Grid as grid, GridCell as gridCell, GridContent, Label, Value } from "../../global/Grid";
import { Internet, Voice, Entertainment, Icon as icon } from "../../Icons";

class Class extends React.Component {
  render() {
    const { details } = this.props;

    if (details.internet || details.voice || details.entertainment) {
      return (
        <Grid>
          <GridCell />
          <GridCell>
            <GridContent noPadding>
              <SectionTitle>Stay Connected</SectionTitle>
              <SectionText>Today’s necessity for personal and business travel.</SectionText>
            </GridContent>
          </GridCell>
          {details.internet ? (
            <>
              <GridCell>
                <Icon>
                  <Internet />
                </Icon>
              </GridCell>
              <GridCell>
                <GridContent>
                  <Label>Internet</Label>
                  <Value>{details.internet}</Value>
                </GridContent>
              </GridCell>
            </>
          ) : null}
          {details.voice ? (
            <>
              <GridCell>
                <Icon>
                  <Voice />
                </Icon>
              </GridCell>
              <GridCell>
                <GridContent>
                  <Label>Voice</Label>
                  <Value>{details.voice}</Value>
                </GridContent>
              </GridCell>
            </>
          ) : null}
          {details.entertainment ? (
            <>
              <GridCell>
                <Icon>
                  <Entertainment />
                </Icon>
              </GridCell>
              <GridCell>
                <GridContent>
                  <Label>Entertainment</Label>
                  <Value>{details.entertainment}</Value>
                </GridContent>
              </GridCell>
            </>
          ) : null}
        </Grid>
      );
    }
    return null;
  }
}

export default Class;

const Grid = styled(grid)`
  grid-template-columns: 200px calc(100% - (200px - 124px));
  background-color: transparent;
  grid-gap: 0;
  padding: 0;
  transform: translateX(-124px);

  @media ${mediaQuery.sm} {
    grid-template-columns: 200px calc(100% - (200px - 155px));
    transform: translateX(-155px);
  }

  @media ${mediaQuery.md} {
    transform: translateX(0);
    grid-template-columns: 280px 1fr;
  }
`;

const GridCell = styled(gridCell)`
  background: ${colors.mineShaft};
  color: white;
  display: flex;

  &:nth-of-type(2) {
    padding-bottom: ${vars.gridGap * 1.5}px;
  }

  &:nth-of-type(2n + 4) {
    box-shadow:
      inset 1px 0 ${colors.tundora},
      inset 0 -1px ${colors.tundora};
  }

  &:nth-of-type(4) {
    box-shadow:
      inset 1px 1px ${colors.tundora},
      inset 0 -1px ${colors.tundora};
  }
`;

const SectionTitle = styled(sectionTitle)`
  margin-bottom: 0;
`;

const Icon = styled(icon)`
  height: 30px;
  width: 30px;
  fill: ${colors.tea};
  margin: auto ${vars.gridGap * 1.5}px auto auto;
`;
