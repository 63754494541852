import React, { useRef, useState, useEffect } from "react";

import styled, { css } from "styled-components/macro";

import InventoryHero from "./components/InventoryHero";
import InventoryResults from "./components/InventoryResults";
import InventorySearch from "./components/InventorySearch";
import InventorySidebar from "./components/InventorySidebar";
import { checkMediaQueryListener } from "../../helpers/scripts";
import { colors, mediaQuery } from "../../helpers/styles";
import Footer from "../Footer";

const scrollToRef = (ref, offset = 0) => window.scrollTo({ top: ref.current.offsetTop + offset, left: 0 });

export default function Inventory(props) {
  const mainRef = useRef();

  const mql = checkMediaQueryListener("lg");

  const [filterState, setFilterState] = useState(false);
  const [mobileResize, setMobileResize] = useState(mql.matches);

  useEffect(() => {
    function handleResize(e) {
      setMobileResize(e.matches);

      if (e.matches) {
        props.toggleLockScroll(false);
      }
    }

    mql.addListener(handleResize);

    // Un-mounts
    return () => mql.removeListener(handleResize);
  }, [mql, props]);

  const scrollToMain = () => {
    mobileResize ? scrollToRef(mainRef) : scrollToRef(mainRef, -65);
  };

  useEffect(() => {
    props.toggleScrolledNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <InventoryHero
        toggleScrolledNav={props.toggleScrolledNav}
        introAnimation={props.introAnimation}
        setIntroAnimation={props.setIntroAnimation}
      />
      {!mobileResize ? (
        <InventorySidebar
          location={props.location}
          history={props.history}
          planes={props.planes}
          scrolledNav={props.scrolledNav}
          scrollToMain={scrollToMain}
          filterState={filterState}
          setFilterState={setFilterState}
          toggleLockScroll={props.toggleLockScroll}
        />
      ) : null}
      <Content ref={mainRef} scrolledNav={props.scrolledNav}>
        {mobileResize ? (
          <InventorySidebar
            location={props.location}
            history={props.history}
            planes={props.planes}
            scrolledNav={props.scrolledNav}
            scrollToMain={scrollToMain}
            filterState={filterState}
            setFilterState={setFilterState}
            toggleLockScroll={props.toggleLockScroll}
          />
        ) : null}

        <ContentBox>
          <InventorySearch
            location={props.location}
            history={props.history}
            scrollToMain={scrollToMain}
            scrolledNav={props.scrolledNav}
          />
          <InventoryResults
            location={props.location}
            history={props.history}
            planes={props.planes}
            onSort={props.onSort}
            toggleLockScroll={props.toggleLockScroll}
            filterState={filterState}
            setFilterState={setFilterState}
            scrollToMain={scrollToMain}
          />
        </ContentBox>
      </Content>
      <Footer />
    </>
  );
}

const Content = styled.main`
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${colors.alabaster};
  width: 100%;
  min-height: 100vh;
  transform: translateY(0);
  transition: 500ms ease;

  @media ${mediaQuery.lg} {
    transform: translateY(0);
  }

  ${(props) =>
    props.scrolledNav &&
    css`
      transform: translateY(0);
      margin-bottom: 0;

      @media ${mediaQuery.lg} {
        /* top: 65px; */
        /* transform: translateY(65px); */
        /* margin-bottom: 65px; */
      }
    `}
`;

const ContentBox = styled.div`
  width: 100%;

  @media ${mediaQuery.lg} {
    width: calc(100% - 340px);
  }
`;
