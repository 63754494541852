import React from "react";

import { rgba } from "polished";
import queryString from "query-string";
import Select from "react-select";

import { vars, colors } from "../../../helpers/styles";
import { updateURL, removeParamFromURL, generateLabels } from "../../../hooks";
import { OptionHeader, OptionTitle } from "../../global/Options";

const FilterManufacturer = (props) => {
  const { history, location, planes } = props;
  const searchParams = queryString.parse(location.search);
  const manufacturerParam = searchParams.manufacturer;
  const dedupeManufcturers = planes.reduce((a, { manufacturer }) => {
    if (!a[manufacturer]) {
      a[manufacturer] = true;
    }
    return a;
  }, {});

  const handleChange = (val = "", { history, location }) => {
    if (val !== null && val.value.length > 0) {
      const g = queryString.parse(location.search);
      const add = updateURL(g, "manufacturer", location.search, val.value);
      history.push(add);
    } else {
      const add = removeParamFromURL("manufacturer", queryString.parse(location.search));
      history.push(`/${add}`);
    }
    props.scrollToMain();
  };

  return (
    <label>
      <OptionHeader>
        <OptionTitle>Manufacturer</OptionTitle>
      </OptionHeader>
      <Select
        isClearable
        styles={selectStyles}
        onChange={(values) => {
          handleChange(values, { location, history });
        }}
        defaultValue={manufacturerParam ? generateLabels(manufacturerParam) : []}
        options={Object.keys(dedupeManufcturers).map((key) => ({
          label: key,
          value: key,
        }))}
      />
    </label>
  );
};

export default FilterManufacturer;

// Sort Styles
const selectStyles = {
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: "realistwide, sans-serif",
    fontWeight: 400,
    letterSpacing: "0.5px",
    background: state.isSelected ? colors.makara : colors.white,
    transition: "100ms",
    "&:hover": {
      background: state.isSelected ? colors.makara : colors.tea,
    },
  }),
  control: (provided, state) => ({
    fontFamily: "realistwide, sans-serif",
    fontWeight: 600,
    letterSpacing: "0.5px",
    boxShadow: state.isFocused
      ? `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.5)}`
      : `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.1)}`,
    border: "none",
    display: "flex",
    cursor: "pointer",
    transition: "200ms",
    background: state.isFocused ? colors.white : "transparent",

    "&:hover": {
      background: colors.white,
      boxShadow: `inset 0 0 0 1px ${rgba(colors.mineShaft, 0.5)}`,
    },
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: colors.mineShaft,
  }),
  indicatorSeparator: () => ({}),
  dropdownIndicator: (provided, state) => ({
    display: "flex",
    color: colors.mineShaft,
    padding: vars.gridGap / 2,
  }),
  placeholder: () => ({
    color: colors.tea,
    fontWeight: 400,
  }),
};
