import React from "react";

import styled, { css } from "styled-components/macro";

import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionContent,
  SectionTitle,
  SectionText,
  SectionSubtitle,
} from "../../../helpers/styles";
import { Button as button } from "../../global/Button";
import { Grid as grid, GridCell, Label, Value } from "../../global/Grid";

const PlaneCTA = (props) => {
  const { details } = props;

  const offerClick = (e) => {
    e.preventDefault();
    props.setOfferModal(true);
  };

  // details shorthand (e. detail('manufacturer'))
  function detail(key) {
    return details[key];
  }

  return (
    <Section altColor>
      <Wrapper>
        <SectionContent>
          {detail("hero_cover") ? <IsolatedImage src={detail("hero_cover")} alt="" /> : null}
          <Grid gridLayout="1fr 200px">
            <GridCell hasValue noPaddingLeft altColor>
              <Label>
                {detail("year_model")} {detail("model")} {detail("serial_number")}
              </Label>
            </GridCell>
            <GridCell rightAlign hasValue altColor>
              <Label>
                {detail("display_price") !== undefined &&
                detail("display_price") !== "" &&
                detail("display_price") !== null
                  ? detail("display_price")
                  : detail("price")}
              </Label>
            </GridCell>
          </Grid>
        </SectionContent>
        <SectionContent>
          <SectionTitle>Inquire Today</SectionTitle>
          <SectionText>
            Active, timely, personalized. We’re ready to guide you to this {detail("manufacturer")}, as one of the
            world's largest international aircraft brokerage teams. We look forward to hearing from you.
          </SectionText>
        </SectionContent>
        <SectionContent>
          <SectionSubtitle>Drop us a line</SectionSubtitle>
          <Grid gridLayout="half">
            <GridCell hasValue altColor>
              <Button
                data-gtm="inquire"
                href={`mailto:${
                  detail("sales_rep_email") !== undefined &&
                  detail("sales_rep_email") !== "" &&
                  detail("sales_rep_email") !== null
                    ? `${
                        detail("sales_rep_name")
                          ? `${detail("sales_rep_name")}<${detail("sales_rep_email")}>`
                          : `${detail("sales_rep_email")}`
                      }`
                    : "info@avprojets.com"
                }?subject=Inquiry Into ${detail("year_model")} ${detail("manufacturer")} ${detail("model")} SN:${detail(
                  "serial_number",
                )}${
                  detail("sales_rep_name") !== undefined &&
                  detail("sales_rep_name") !== "" &&
                  detail("sales_rep_name") !== null
                    ? `&body=To ${detail("sales_rep_name")},`
                    : "&body=To Whomever it May Concern,"
                }${
                  detail("sales_rep_email") !== undefined &&
                  detail("sales_rep_email") !== "" &&
                  detail("sales_rep_email") !== null
                    ? "&bcc=info@avprojets.com"
                    : ""
                }
                `}
                target="_blank"
                rel="noopener noreferrer"
              >
                Email Us
              </Button>
            </GridCell>
            <GridCell hasValue altColor>
              <Button as="button" data-gtm="offer form" secondary="true" onClick={offerClick}>
                Make an Offer
              </Button>
            </GridCell>
          </Grid>
          <Legal>
            Specifications are provided for informational purposes only and do not constitute representations or
            warranties. These specifications are subject to buyer’s independent verification upon inspection. The
            Aircraft is subject to prior sale, lease, and/or removal from the market without prior notice.
          </Legal>
        </SectionContent>
      </Wrapper>
    </Section>
  );
};

export default PlaneCTA;

const Section = styled(section)`
  background: ${colors.mineShaft};
  z-index: 1;

  ${(props) =>
    props.altColor &&
    css`
      ${SectionTitle} {
        color: ${colors.bermudaGray};
        text-align: center;
      }

      ${SectionText} {
        ${font({
          size: "20px",
          color: colors.white,
        })};
        max-width: 550px;
        margin: 0 auto;
        position: relative;

        &::before {
          content: "—";
          margin-right: ${vars.gridGap / 2}px;
        }

        @media ${mediaQuery.sm} {
          &::before {
            position: absolute;
            left: 0;
            transform: translateX(calc(-100% - ${vars.gridGap / 2}px));
          }
        }
      }

      ${SectionSubtitle} {
        color: ${colors.makara};
        text-align: center;
      }

      ${Label} {
        color: ${colors.makara};
        font-weight: 800;
        margin: ${vars.gridGap}px 0;
      }

      ${Value} {
        color: ${colors.white};
        font-weight: 200;
      }

      ${Grid} {
        background: ${(props) => (props.noPadding ? "transparent" : colors.tundora)};
      }
    `}
`;

const IsolatedImage = styled.img`
  width: 80%;
  max-width: 400px;
  object-fit: contain;
  margin: 0 auto ${vars.gridGap * 4}px;
  display: block;
`;

const Grid = styled(grid)`
  grid-template-columns: 1fr;
  text-align: center;
  margin-left: -1px;

  @media ${mediaQuery.sm} {
    text-align: initial;
    grid-template-columns: ${(props) => (props.gridLayout === "1fr 200px" ? "1fr 200px" : "1fr 1fr")};
  }
`;

const Legal = styled.p`
  ${font({
    name: "realist",
    size: "10px",
    weight: 400,
    color: colors.makara,
    letterSpacing: "1px",
    lineHeight: 1.6,
    transform: "uppercase",
  })};
  width: 100%;
  margin-top: ${vars.gridGap * 2}px;
`;

const Button = styled(button)`
  margin: ${vars.gridGap}px auto;
`;
