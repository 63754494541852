import React, { useState, useEffect } from "react";

import { rgba } from "polished";
import styled, { css } from "styled-components/macro";

import Occupancy from "./Occupancy";
import { checkMediaQueryListener } from "../../../helpers/scripts";
import { vars, colors, font, mediaQuery, SectionTitle as sectionTitle, SectionText } from "../../../helpers/styles";
import { Grid as grid, GridContent, Label, Value } from "../../global/Grid";
import {
  Jumpseat,
  Avionics,
  Coffee,
  Fwd,
  ClubSeat,
  Lavatory,
  Luggage,
  Brochure,
  ChevronRight,
  Icon as icon,
  VirtualTour,
  Gallery,
} from "../../Icons";

const PlaneMap = (props) => {
  const mql = checkMediaQueryListener("md");

  const [mobileResize, setMobileResize] = useState(mql.matches);

  useEffect(() => {
    function handleResize(e) {
      setMobileResize(e.matches);
    }
    mql.addListener(handleResize);

    // Unmounts
    return () => mql.removeListener(handleResize);
  }, [mql]);

  return (
    <Grid>
      <GridCell>
        {mobileResize && props.details.matterport !== undefined && props.details.matterport ? (
          <VirtualTourLink
            vr={props.details.matterport}
            setLightboxSrc={props.setLightboxSrc}
            setLightbox={props.setLightbox}
            lightbox={props.lightbox}
          />
        ) : null}
      </GridCell>
      <GridCell>
        <GridContent noPadding>
          <SectionTitle>Cabin Experience</SectionTitle>
          <SectionText>
            Seats {props.details.total_passengers} total passengers
            {props.details.places_to_sleep ? `, with ${props.details.places_to_sleep} places to sleep` : null}.
          </SectionText>
          {!mobileResize && props.details.matterport !== undefined && props.details.matterport ? (
            <VirtualTourLink
              vr={props.details.matterport}
              setLightboxSrc={props.setLightboxSrc}
              setLightbox={props.setLightbox}
              lightbox={props.lightbox}
            />
          ) : null}
        </GridContent>
      </GridCell>
      <PlaneAvionics
        passengers={props.details.total_passengers}
        beds={props.details.places_to_sleep}
        details={{
          avionics: props.details.avionics_suite,
          vr: props.details.matterport,
          pdf: props.details.brochure_link,
          navigationCompliance: props.details.navigation_compliance,
        }}
        avionics={props.details.avionics_suite}
      />
      {/* Fwd Galley/Lavatory/Crew */}
      {(props.details.forward_gallery !== undefined && props.details.forward_gallery) ||
      (props.details.forward_lavatory !== undefined && props.details.forward_lavatory) ||
      (props.details.forward_crew_rest !== undefined && props.details.forward_crew_rest) ? (
        <PlaneZone
          icon="fwd"
          details={{
            galley: {
              zone: "Forward Galley",
              text: props.details.forward_gallery,
            },
            lavatory: {
              zone: "Forward Lavatory",
              text: props.details.forward_lavatory,
            },
            crew: {
              zone: "Forward Crew Rest",
              text: props.details.forward_crew_rest,
            },
          }}
        />
      ) : null}

      {/* First Zone */}
      {props.details.first_zone !== undefined && props.details.first_zone ? (
        <PlaneZone
          icon="clubseat"
          details={{
            zone: "First Zone",
            text: props.details.first_zone,
            image: props.details.first_zone_image,
            vr: props.details.first_zone_vr,
            // vr: '../images/hero.jpg'
          }}
          setLightboxSrc={props.setLightboxSrc}
          lightbox={props.lightbox}
          setLightbox={props.setLightbox}
        />
      ) : null}

      {/* Second Zone */}
      {props.details.second_zone !== undefined && props.details.second_zone ? (
        <PlaneZone
          icon="clubseat"
          details={{
            zone: "Second Zone",
            text: props.details.second_zone,
            image: props.details.second_zone_image,
            vr: props.details.second_zone_vr,
          }}
          setLightboxSrc={props.setLightboxSrc}
          lightbox={props.lightbox}
          setLightbox={props.setLightbox}
        />
      ) : null}

      {/* Third Zone */}
      {props.details.third_zone !== undefined && props.details.third_zone ? (
        <PlaneZone
          icon="clubseat"
          details={{
            zone: "Third Zone",
            text: props.details.third_zone,
            image: props.details.third_zone_image,
            vr: props.details.third_zone_vr,
          }}
          setLightboxSrc={props.setLightboxSrc}
          lightbox={props.lightbox}
          setLightbox={props.setLightbox}
        />
      ) : null}

      {/* Fourth Zone */}
      {props.details.fourth_zone !== undefined && props.details.fourth_zone ? (
        <PlaneZone
          icon="clubseat"
          details={{
            zone: "Fourth Zone",
            text: props.details.fourth_zone,
            image: props.details.fourth_zone_image,
            vr: props.details.fourth_zone_vr,
          }}
          setLightboxSrc={props.setLightboxSrc}
          lightbox={props.lightbox}
          setLightbox={props.setLightbox}
        />
      ) : null}

      {/* Fifth Zone */}
      {props.details.fifth_zone !== undefined && props.details.fifth_zone ? (
        <PlaneZone
          icon="clubseat"
          details={{
            zone: "Fifth Zone",
            text: props.details.fifth_zone,
            image: props.details.fifth_zone_image,
            vr: props.details.fifth_zone_vr,
          }}
          setLightboxSrc={props.setLightboxSrc}
          lightbox={props.lightbox}
          setLightbox={props.setLightbox}
        />
      ) : null}

      {/* Aft Galley */}
      {props.details.aft_gallery !== undefined && props.details.aft_gallery ? (
        <PlaneZone
          icon="coffee"
          details={{
            zone: "Aft Galley",
            text: props.details.aft_gallery,
          }}
        />
      ) : null}

      {/* Aft Lavatory */}
      {props.details.aft_lavatory !== undefined && props.details.aft_lavatory ? (
        <PlaneZone
          icon="lavatory"
          details={{
            zone: "Aft Lavatory",
            text: props.details.aft_lavatory,
          }}
        />
      ) : null}

      {/* Baggage */}
      <PlaneBaggage passengers={props.details.total_passengers} baggage={props.details.baggage_volume} />
    </Grid>
  );
};

export default PlaneMap;

const PlaneAvionics = (props) => (
  <>
    <GridCell spanRow={2} planeGraphic>
      <PlaneLine cockpit />
      <PlaneBox cockpit vr={!!(props.details.avionics !== undefined && props.details.vr)}>
        <CockpitGraphic>
          <Cockpit />
          <PlaneSegment
            transparent
            data-gtm="brochure"
            as={props.details.avionics !== undefined && props.details.pdf ? "a" : null}
            href={props.details.avionics !== undefined && props.details.pdf ? props.details.pdf : null}
            target={props.details.avionics !== undefined && props.details.pdf ? "_blank" : null}
          >
            <PlaneSegmentIcon>
              <Avionics />
            </PlaneSegmentIcon>
            {props.details.avionics !== undefined && props.details.pdf ? (
              <LinkChevron>
                <ChevronRight />
              </LinkChevron>
            ) : null}
          </PlaneSegment>
        </CockpitGraphic>
      </PlaneBox>
    </GridCell>
    <GridCell planeData>
      <Occupancy passengers={props.passengers} beds={props.beds} />
    </GridCell>
    <GridCell planeData avionics noPadding>
      {props.details.avionics ? (
        <GridContent>
          <Label>Avionics</Label>
          <Value>{props.details.avionics}</Value>
          <Label>Navigation Compliance</Label>
          <Value>{props.details.navigationCompliance}</Value>
          {props.details.avionics !== undefined && props.details.pdf ? (
            <PdfLink data-gtm="brochure" href={props.details.pdf} target="_blank" rel="noopener noreferrer">
              <PdfLinkIcon as="span">
                <Brochure />
              </PdfLinkIcon>
              PDF Details
            </PdfLink>
          ) : null}
        </GridContent>
      ) : null}
    </GridCell>
  </>
);

const PlaneZone = (props) => {
  const [image, setImage] = useState(props.details.image);
  const [vr, setVr] = useState(props.details.vr);

  return (
    <>
      <GridCell planeGraphic zone={props.details.galley ? "fwd" : props.details.zone}>
        <PlaneLine />
        <PlaneBox>
          <PlaneSegment hover={props.details.image || props.details.vr ? true : null}>
            <PlaneSegmentIcon>
              {(() => {
                switch (props.icon) {
                  case "jumpseat":
                    return <Jumpseat />;
                  case "fwd":
                    return <Fwd />;
                  case "coffee":
                    return <Coffee />;
                  case "clubseat":
                    return <ClubSeat />;
                  case "lavatory":
                    return <Lavatory />;
                  default:
                    return null;
                }
              })()}
            </PlaneSegmentIcon>
            {props.details.image || props.details.vr ? (
              <LinkChevron>
                <ChevronRight />
              </LinkChevron>
            ) : null}
            <PlaneLinks>
              {props.details.vr ? (
                <PlaneHoverLink
                  setVr={setVr}
                  vr={vr}
                  vrLink={props.details.vr}
                  setLightboxSrc={props.setLightboxSrc}
                  lightbox={props.lightbox}
                  setLightbox={props.setLightbox}
                />
              ) : null}
              {props.details.image ? (
                <PlaneHoverLink
                  setImage={setImage}
                  image={image}
                  imageLink={props.details.image}
                  setLightboxSrc={props.setLightboxSrc}
                  lightbox={props.lightbox}
                  setLightbox={props.setLightbox}
                />
              ) : null}
            </PlaneLinks>
          </PlaneSegment>
        </PlaneBox>
      </GridCell>
      <GridCell planeData noPadding>
        <GridContent>
          {props.details.zone ? (
            <>
              <Label>{props.details.zone}</Label>
              <Value>{props.details.text}</Value>
            </>
          ) : null}
          {props.details.galley && props.details.galley.text ? (
            <>
              <Label>{props.details.galley.zone}</Label>
              <Value>{props.details.galley.text}</Value>
            </>
          ) : null}
          {props.details.lavatory && props.details.lavatory.text ? (
            <>
              <Label>{props.details.lavatory.zone}</Label>
              <Value>{props.details.lavatory.text}</Value>
            </>
          ) : null}
          {props.details.crew && props.details.crew.text ? (
            <>
              <Label>{props.details.crew.zone}</Label>
              <Value>{props.details.crew.text}</Value>
            </>
          ) : null}
        </GridContent>
      </GridCell>
    </>
  );
};

const PlaneBaggage = (props) => (
  <>
    <GridCell planeGraphic>
      <PlaneLine baggage />
      <PlaneBox baggage>
        <BaggageGraphic>
          <BaggageArea />
        </BaggageGraphic>
        <PlaneSegment transparent>
          <PlaneSegmentIcon>
            <Luggage />
          </PlaneSegmentIcon>
        </PlaneSegment>
      </PlaneBox>
    </GridCell>
    <GridCell planeData noPadding>
      <GridContent>
        <Label>Baggage Volume</Label>
        <Value>
          {props.baggage} ft<sup>3</sup> — Comfortably fits {props.passengers || null} or more full-sized bags
        </Value>
      </GridContent>
    </GridCell>
  </>
);

const PlaneHoverLink = (props) => (
  <PlaneLink
    href={props.vr ? props.vrLink : props.imageLink}
    data-gtm={props.vr ? "virtual_tour" : "image"}
    onClick={(e) => {
      e.preventDefault();
      props.setLightbox(!props.lightbox);
      if (props.vr) {
        props.setLightboxSrc(
          <iframe
            title="lightbox"
            src={props.vrLink}
            width="1920px"
            height="1080px"
            style={{ border: "none", background: "black" }}
          />,
        );
      } else if (props.image) {
        props.setLightboxSrc(
          <img
            src={props.imageLink}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />,
        );
      }
    }}
  >
    <PlaneLinkIcon>{props.vr ? <VirtualTour /> : props.image ? <Gallery /> : null}</PlaneLinkIcon>
    <PlaneLinkText>{props.vr ? "VR" : props.image ? "Image" : null}</PlaneLinkText>
  </PlaneLink>
);

const VirtualTourLink = (props) => (
  <VirtualTourCTA
    data-gtm="virtual_tour"
    onClick={(e) => {
      e.preventDefault();
      props.setLightbox(!props.lightbox);
      if (props.vr) {
        props.setLightboxSrc(
          <iframe
            title="Virtual Tour"
            src={props.vr}
            width="1920px"
            height="1080px"
            style={{
              border: "none",
              background: "black",
            }}
          />,
        );
      }
    }}
    href={props.vr}
  >
    <VirtualTourIcon>
      <VirtualTour />
    </VirtualTourIcon>
    <VirtualTourText>
      Launch Virtual&nbsp;Tour{" "}
      <VirtualTourTextChevron as="span">
        <ChevronRight />
      </VirtualTourTextChevron>
    </VirtualTourText>
  </VirtualTourCTA>
);

const Cockpit = () => (
  <svg viewBox="0 0 150 188">
    <path
      d="M0,188H0V154.89c0-8.26,2.53-21.9,7.12-38.41A433.11,433.11,0,0,1,25.23,64.82c7.91-18.64,16.3-34.32,24.24-45.35A70.82,70.82,0,0,1,62.38,5.27C66.82,1.77,71.07,0,75,0s8.18,1.78,12.62,5.3a70.88,70.88,0,0,1,12.91,14.26c7.94,11.07,16.33,26.82,24.24,45.54A436.1,436.1,0,0,1,142.88,117c4.59,16.56,7.12,30.23,7.12,38.49V188Zm118.6-80.54h0c1.06,1.28,2.08,2.62,3,4a1.9,1.9,0,0,0,1.52.8,1.86,1.86,0,0,0,1.11-.4l4-3.23a2,2,0,0,0,.61-2.23c-.49-1.32-1-2.65-1.56-4h0c-3.78-9.58-8.46-17.25-14.29-23.47a60,60,0,0,0-14.7-11.48l-.12-.07a46.1,46.1,0,0,0-16.35-5.6l-.25,0a1.9,1.9,0,0,0-1.87,1.86L79,84.43a1.92,1.92,0,0,0,1.71,2,51.5,51.5,0,0,1,10.85,2.22A59.22,59.22,0,0,1,105.26,96c9.41,6.45,13.2,11.48,13.23,11.53l.1,0ZM68.38,61.8l-.25,0a46.1,46.1,0,0,0-16.35,5.6l-.12.07A59.76,59.76,0,0,0,37,79c-5.83,6.21-10.51,13.89-14.29,23.46h0c-.54,1.3-1.07,2.63-1.56,4a2,2,0,0,0,.61,2.22l4,3.23a1.74,1.74,0,0,0,1.11.4,1.85,1.85,0,0,0,1.52-.8c1-1.37,2-2.71,3-4l.1,0c.16-.21,4-5.18,13.23-11.53a59.49,59.49,0,0,1,13.71-7.33,51.5,51.5,0,0,1,10.85-2.22,1.91,1.91,0,0,0,1.71-2l-.79-20.77A1.9,1.9,0,0,0,68.38,61.8Z"
      fill="#6f91a6"
    />
    <path d="M7,188V145.41L18.17,118l14.17,6.85h89.17L132.77,118,143,152.27V188Z" fill="#546a77" />
  </svg>
);

const BaggageArea = () => (
  <svg viewBox="0 0 150 80">
    <path
      id="Path_11"
      data-name="Path 11"
      d="M144,80c3.77-17.16,6-31.82,6-41V0H0V39.66C0,48.74,2.15,63.15,5.84,80Z"
      fill="#6f91a6"
    />
    <path id="Path_181" data-name="Path 181" d="M7,0V47.21L16,70H133.15L143,39.61V0Z" fill="#546a77" />
  </svg>
);

const Grid = styled(grid)`
  grid-template-columns: 200px calc(100% - (200px - 124px));
  background-color: transparent;
  grid-gap: 0;
  padding: 0;
  transform: translateX(-124px);

  @media ${mediaQuery.sm} {
    grid-template-columns: 200px calc(100% - (200px - 155px));
    transform: translateX(-155px);
  }

  @media ${mediaQuery.md} {
    transform: translateX(0);
    grid-template-columns: 280px 1fr;
  }
`;

const GridCell = styled.div`
  background: ${colors.mineShaft};
  color: white;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: center;

  &:last-of-type {
    border-bottom: 1px solid ${colors.tundora};
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    padding-bottom: ${vars.gridGap * 1.5}px;
  }

  &:nth-of-type(3) {
    grid-row: span 2;
  }

  ${GridContent} {
    padding: 16px;
  }

  ${(props) =>
    props.planeGraphic &&
    css`
      padding: ${vars.gridGap / 4}px 0;
      display: flex;
      align-items: stretch;
    `}

  ${(props) =>
    props.planeData &&
    css`
      color: ${colors.tundora};
      border-top: 1px solid;
      border-left: 1px solid;
    `}

  ${(props) =>
    props.zone === "fwd" &&
    css`
      ${PlaneBox} {
        max-height: 82px;
        margin: auto;
      }
    `}
`;

const SectionTitle = styled(sectionTitle)`
  margin-bottom: 0;
`;

const PlaneBox = styled.div`
  width: 150px;
  height: 100%;
  margin: auto auto 0;
  position: relative;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.cockpit &&
    css`
      ${PlaneSegment} {
        height: 55px;
        position: absolute;
        bottom: 0;
        align-self: flex-end;
        margin-top: auto;
      }

      @media ${mediaQuery.md} {
        &::before {
          content: "";
          flex: 1;
          min-height: 40px;
          width: ${(props) => (props.vr ? "2px" : "0px")};
          background: ${colors.tundora};
          display: block;
          margin: auto auto ${vars.gridGap}px;
        }
      }
    `}

  ${(props) =>
    props.baggage &&
    css`
      ${PlaneSegment} {
        margin-bottom: auto;
        height: 65px;
        position: absolute;
        top: 0;
      }
    `}
`;

const PlaneSegment = styled.div`
  width: 150px;
  background-color: ${colors.blueBayoux};
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow:
    inset 7px 0 0 ${colors.bermudaGray},
    inset -7px 0 0 ${colors.bermudaGray};
  position: relative;
  height: 80px;
  margin: auto;
  opacity: 1;
  transition: 300ms ease-out;
  transition-delay: 150ms;

  ${(props) =>
    props.transparent &&
    css`
      box-shadow: none;
      background: transparent;
    `}

  @media ${mediaQuery.md} {
    ${(props) =>
      props.hover &&
      css`
        &:hover {
          background-color: transparent;
          transition-delay: 0ms;
          box-shadow:
            inset 7px 0 0 ${rgba(colors.bermudaGray, 0.0001)},
            inset -7px 0 0 ${rgba(colors.bermudaGray, 0.0001)};

          ${LinkChevron} {
            opacity: 0;
            transition-delay: 0ms;
          }

          ${PlaneSegmentIcon} {
            opacity: 0;
            transition-delay: 0ms;
          }
        }
      `}
  }
`;

const PlaneSegmentIcon = styled(icon)`
  height: 30px;
  width: 38px;
  fill: ${colors.mineShaft};
  opacity: 1;
  transform: translateX(32px);

  @media ${mediaQuery.md} {
    transition: 300ms ease-out;
    transition-delay: 150ms;
    transform: translateX(0);
  }
`;

const PlaneLinks = styled.div`
  display: none;

  @media ${mediaQuery.md} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 125%;
    height: 100%;
    max-height: 75px;
    background-color: transparent;
    display: flex;
    align-items: center;
    transform: scale(0.9) translateX(-10%);
    opacity: 0;
    transition:
      transform 300ms ease-out,
      opacity 200ms;

    ${PlaneSegment}:hover & {
      opacity: 1;
      transition-delay: 150ms;
      transform: scale(1) translateX(-10%);
    }
  }
`;

const PlaneLink = styled.a`
  flex: 1;
  text-decoration: none;
  background: ${colors.white};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.makara};
  margin: 0;
  transition: 300ms;
  flex-direction: column;

  &:hover {
    background: ${colors.alto};
  }

  & + & {
    border-left: 2px solid;
  }
`;

const PlaneLinkIcon = styled(icon)`
  fill: currentColor;
  height: 30px;
  width: 30px;
  margin-top: auto;
`;

const LinkChevron = styled(icon)`
  display: none;

  @media ${mediaQuery.md} {
    display: flex;
    height: 14px;
    width: 8px;
    fill: ${colors.mineShaft};
    top: 0;
    bottom: 0;
    margin: auto 0;
    position: absolute;
    right: ${vars.gridGap * 1.25}px;
    transition: 300ms ease-out;
    transition-delay: 150ms;
  }
`;

const PlaneLinkText = styled.p`
  ${font({
    name: "realist",
    size: "10px",
    weight: 800,
    letterSpacing: "1px",
    transform: "uppercase",
  })};
  margin: ${vars.gridGap / 4}px 0 auto;
`;

const PdfLink = styled.a`
  ${font({
    name: "realist",
    size: "12px",
    weight: 900,
    letterSpacing: "1px",
    color: colors.bermudaGray,
    transform: "uppercase",
  })};
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  margin: ${vars.gridGap / 2}px 0 0 -${vars.gridGap / 4}px;
  transition: 300ms;
  padding: ${vars.gridGap / 4}px ${vars.gridGap / 2}px;
  border-radius: ${vars.gridGap / 4}px;

  &:hover,
  &:focus {
    background-color: ${colors.bermudaGray};
    color: ${colors.white};
  }
`;

const PdfLinkIcon = styled(icon)`
  height: 14px;
  width: 14px;
  fill: currentColor;
  margin-right: ${vars.gridGap / 2}px;
`;

const CockpitGraphic = styled.div`
  height: 188px;
  width: 150px;
  position: relative;
`;

const BaggageGraphic = styled.div`
  height: 80px;
  width: 150px;
`;

const VirtualTourCTA = styled.a`
  display: inline-flex;
  color: ${colors.makara};
  align-items: center;
  text-decoration: none;
  margin: ${vars.gridGap}px 0 0;

  &::before {
    content: "";
    position: absolute;
    width: 100px;
    height: 2px;
    background: ${colors.tundora};
    display: block;
    transform: translateX(calc(-100% - ${vars.gridGap}px));
  }

  @media ${mediaQuery.md} {
    margin: auto auto 0;
    max-width: 150px;
    flex-direction: column;

    &::before {
      content: none;
    }
  }
`;

const VirtualTourIcon = styled(icon)`
  height: 30px;
  width: 30px;
  fill: currentColor;
  margin-right: ${vars.gridGap / 2}px;

  @media ${mediaQuery.md} {
    margin-right: 0;
    margin-bottom: ${vars.gridGap}px;
  }
`;

const VirtualTourText = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 900,
    letterSpacing: "1px",
    transform: "uppercase",
    lineHeight: 1.4,
  })};
  margin: 0;
  display: flex;
  text-align: center;

  @media ${mediaQuery.md} {
    max-width: 125px;
  }
`;

const VirtualTourTextChevron = styled(icon)`
  height: 14px;
  width: 8px;
  fill: currentColor;
  display: inline-flex;
  align-self: flex-end;
  margin-left: ${vars.gridGap / 2}px;
`;

const PlaneLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 2px;
  background:
    linear-gradient(90deg, #262626 1px, transparent 1%) center,
    linear-gradient(#262626 3px, transparent 1%) center,
    rgba(255, 255, 255, 0.8);
  background-size: 14px 14px;
  margin: auto;

  ${(props) =>
    props.baggage &&
    css`
      height: 35px;
      margin-top: 0;
    `}

  ${(props) =>
    props.cockpit &&
    css`
      height: 35px;
      margin-bottom: 0;
    `}
`;
