import { rgba } from "polished";
import styled, { css } from "styled-components/macro";

import { vars, colors, font } from "../../helpers/styles";

export const Button = styled.a`
  ${font({
    name: "realist",
    size: "14px",
    weight: 900,
    color: colors.black,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  padding: ${vars.gridGap * 0.75}px ${vars.gridGap}px;
  text-decoration: none;
  border-radius: 25px;
  min-width: 220px;
  margin: 0 auto;
  display: inline-block;
  text-align: center;
  box-shadow: inset 0 0 0 2px ${colors.tea};
  background-color: transparent;
  color: ${colors.makara};
  transition:
    250ms box-shadow,
    250ms color,
    250ms background-color;
  cursor: pointer;
  border: none;

  &:hover {
    background-color: ${colors.makara};
    color: ${colors.white};
    box-shadow: inset 0 0 0 2px ${colors.makara};
  }

  ${(props) =>
    props.primary &&
    css`
      font-weight: 600;
      background-color: ${colors.blueGem};
      color: ${colors.white};
      box-shadow: inset 0 0 0 2px ${rgba(colors.blueGem, 0.00002)};

      &:hover {
        background-color: ${colors.white};
        color: ${colors.blueGem};
        box-shadow: inset 0 0 0 2px ${colors.blueGem};
      }
    `}

  ${(props) =>
    props.secondary &&
    css`
      font-weight: 600;
      background-color: ${colors.white};
      color: ${colors.blueGem};
      box-shadow: none;

      &:hover {
        background-color: ${colors.blueGem};
        color: ${colors.white};
        box-shadow: none;
      }
    `}
`;
