import React, { useEffect, useRef } from "react";

import { withRouter, Link as link } from "react-router-dom";

import gsap from "gsap";
import styled, { css } from "styled-components/macro";

import { vars, colors, font, mediaQuery, mediaQueryDown } from "../../helpers/styles";
import { Button as button } from "../global/Button";
import { Logo } from "../Icons";

const Nav = (props) => {
  const locPath = props.location.pathname;
  const navRef = useRef();
  const logoRef = useRef();
  const linksRef = useRef();
  const buttonRef = useRef();

  useEffect(() => {
    gsap
      .timeline({ delay: 1 })
      .fromTo(
        [
          logoRef.current,
          linksRef.current.children[0],
          linksRef.current.children[1],
          linksRef.current.children[2],
          linksRef.current.children[3],
          buttonRef.current,
        ],
        { y: "-100%", autoAlpha: 0 },
        { duration: 0.75, stagger: 0.25, y: "0%", autoAlpha: 1 },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.notification.is_visible) {
      gsap
        .timeline({
          delay: 1,
        })
        .fromTo(navRef.current, { top: 0 }, { duration: 0.75, top: 62 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notification, props.notification.is_visible]);

  return (
    <NavBar
      scrolledNav={props.scrolledNav}
      location={locPath}
      ref={navRef}
      notification={props.notification.is_visible}
    >
      <LogoBox to="/" innerRef={logoRef}>
        <Logo />
      </LogoBox>
      <NavContent>
        <Links ref={linksRef}>
          <Link
            to="/"
            params={{
              isActive:
                locPath !== "/company" &&
                locPath !== "/contact" &&
                locPath !== "/services" &&
                locPath !== "/financial-advisory-services"
            }}
          >
            <LinkText>Inventory</LinkText>
          </Link>
          <Link to="/company" params={{ isActive: locPath === "/company" }}>
            <LinkText>Company</LinkText>
          </Link>
          <Link to="/services" params={{ isActive: locPath === "/services" }}>
            <LinkText>Services</LinkText>
          </Link>
          <Link to="/financial-advisory-services" params={{ isActive: locPath === "/financial-advisory-services" }}>
            <LinkText>Financial Advisory Services</LinkText>
          </Link>
        </Links>

        <Button as={link} ref={buttonRef} to="/contact" params={{ isActive: false }}>
          Contact
        </Button>
      </NavContent>
    </NavBar>
  );
};

export default withRouter(Nav);

const NavBar = styled.div`
  display: none;

  @media ${mediaQuery.lg} {
    height: ${vars.navBar};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    z-index: 2;
    align-items: center;
    background: transparent;
    justify-content: space-between;
    padding: 0 ${vars.gridGap * 2.5}px 0 ${vars.gridGap * 5}px;

    ${(props) =>
      props.notification &&
      css`
        top: 62px;
      `}
  }

  ${(props) =>
    ["/services", "/financial-advisory-services"].includes(props.location) &&
    css`
      ${Link}::after {
        box-shadow: 0 2px ${colors.tea};
      }

      ${LinkText} {
        color: ${colors.white};
      }

      ${LogoBox} svg {
        fill: ${colors.white}
      }

      ${Button} {
        color: ${colors.white}
        box-shadow: inset 0 0 0 2px ${colors.tea};

        &:hover {
          color: ${colors.mineShaft};
          background: ${colors.white}
          box-shadow: inset 0 0 0 2px ${colors.white};
        }
      }
    `}
`;

const LogoBox = styled(link)`
  width: 134px;
  height: auto;
  opacity: 0;

  svg {
    width: 100%;
    height: auto;
    object-fit: contain;
    pointer-events: none;
  }
`;

const NavContent = styled.nav`
  display: flex;
  align-items: center;
`;

const Links = styled.div`
  display: flex;
  align-items: center;
`;

const Link = styled(link)`
  position: relative;
  text-decoration: none;
  padding: ${vars.gridGap}px;
  opacity: 0;

  &::after {
    content: "";
    height: 2px;
    display: block;
    box-shadow: 0 2px ${colors.blueGem};
    width: calc(100% - ${vars.gridGap * 2}px);
    position: absolute;
    bottom: ${vars.gridGap}px;
    opacity: 0;
    transition: opacity 200ms;
  }

  &:hover::after {
    opacity: 1;
  }

  ${(props) =>
    props.params.isActive &&
    css`
      &::after {
        opacity: 1;
      }

      ${LinkText} {
        transform: translateY(-${vars.gridGap / 3}px);
      }
    `}
`;

const LinkText = styled.p`
  ${font({
    name: "realist",
    size: "14px",
    weight: 600,
    color: colors.black,
    letterSpacing: "2px",
    transform: "uppercase",
  })};
  margin: 0;
  transition:
    transform 200ms,
    color 250ms;

  ${Link}:hover & {
    transform: translateY(-${vars.gridGap / 3}px);
  }
`;

const Button = styled(button)`
  font-weight: 600;
  box-shadow: inset 0 0 0 2px ${colors.blueGem};
  color: ${colors.black};
  margin-left: ${vars.gridGap}px;
  cursor: pointer;
  opacity: 0;
  transition:
    background-color 250ms,
    box-shadow 250ms,
    color 250ms;

  &:hover {
    background-color: ${colors.blueGem};
    box-shadow: inset 0 0 0 2px ${colors.blueGem};
    color: ${colors.white};
  }

  ${(props) =>
    props.params.isActive &&
    css`
      background-color: ${colors.blueGem};
      box-shadow: inset 0 0 0 2px ${colors.blueGem};
      color: ${colors.white};
    `}
`;
