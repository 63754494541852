import React from "react";

import PlaneFlightRange from "./PlaneFlightRange";
import { Wrapper, Section, SectionContent, SectionTitle, SectionSubtitle } from "../../../helpers/styles";
import { Grid, GridDataCell } from "../../global/Grid";

const PlaneMetricsPerformance = (props) => {
  const { details } = props;

  // details shorthand (e. detail('manufacturer'))
  function detail(key) {
    return details[key];
  }

  function commaSeparator(input) {
    return `${input}`.replace(/\B(?=(\d{3})+\b)/g, ",");
  }

  return (
    <Section
      id="metrics"
      ref={props.metricsRef}
      clearTop={!!(detail("highlight1") && detail("highlight2") && detail("highlight3"))}
    >
      <Wrapper>
        <SectionContent>
          <SectionTitle>Metrics &amp; Performance</SectionTitle>

          {detail("flight_range") ? (
            <>
              <SectionSubtitle>Max Flight Range</SectionSubtitle>
              <PlaneFlightRange range={detail("flight_range") ? detail("flight_range") : null} />
            </>
          ) : null}
        </SectionContent>
        <SectionContent>
          <SectionSubtitle>Model Performance</SectionSubtitle>
          <Grid gridLayout="thirds">
            <GridDataCell label="Cabin Class" value={detail("cabin_class")} noPaddingLeft />
            <GridDataCell label="Flight Range (NM)" value={detail("flight_range") ? detail("flight_range") : null} />
            <GridDataCell label="Range Assumptions" value={detail("range_assumptions")} />
            <GridDataCell
              label="Balance Field Length (FT)"
              value={detail("balance_field_length") ? detail("balance_field_length") : null}
              noPaddingLeft
            />
            <GridDataCell label="Normal Cruise Speed (TAS)" value={detail("normal_cruise_speed_tas")} />
            <GridDataCell label="Service Ceiling (FT)" value={commaSeparator(detail("service_ceiling_ft"))} />
          </Grid>
        </SectionContent>
      </Wrapper>
    </Section>
  );
};

export default PlaneMetricsPerformance;
