import React, { useRef } from "react";

import styled from "styled-components/macro";

import ServicesCTA from "./components/ServicesCTA";
import ServicesHero from "./components/ServicesHero";
import ServicesInterstitial from "./components/ServicesInterstitial";
import ServicesIntro from "./components/ServicesIntro";
import ServicesMarketAd from "./components/ServicesMarketAd";
import ServicesMarketAnalysis from "./components/ServicesMarketAnalysis";
import ServicesServices from "./components/ServicesServices";
import ServicesServingYou from "./components/ServicesServingYou";
import ServicesTransactionDoc from "./components/ServicesTransactionDoc";
import Footer from "../Footer";

const Services = (props) => {
  const servingRef = useRef();

  return (
    <>
      <ServicesHero toggleScrolledNav={props.toggleScrolledNav} />
      <Content>
        <ServicesIntro servingRef={servingRef} />
        <ServicesServingYou servingRef={servingRef} />
        <ServicesInterstitial sourceImage="../images/orangeCloudsRight.jpg" clearBottom clearTop />
        <ServicesServices />
        <ServicesInterstitial sourceImage="../images/avJet.jpg" mobilePosition="85%" clearBottom clearTop />
        <ServicesMarketAnalysis />
        <ServicesMarketAd />
        <ServicesTransactionDoc />
        {/* <CompanyIntro />
        <CompanyKnowledge />
        <CompanyPerformance />
        <CompanyIntegrity />
        <CompanyJourney /> */}
      </Content>
      <ServicesCTA />
      <Footer />
    </>
  );
};

export default Services;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
