import React from "react";

import { Link as link } from "react-router-dom";

import styled, { css } from "styled-components/macro";

import { Logo } from "./Icons";
import { vars, colors, font, mediaQuery, Wrapper, Section as section, SectionContent } from "../helpers/styles";

const Footer = (props) => {
  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <Section footerUpgrade={props.footerUpgrade}>
      <Wrapper>
        <SectionContent>
          <FooterRow>
            <FooterCol>
              <FooterBox>
                <LogoBox to="/">
                  <Logo />
                </LogoBox>
              </FooterBox>
              <FooterBox mobileHide>
                <Text>Serving&nbsp;customers around&nbsp;the&nbsp;world</Text>
              </FooterBox>
              <FooterBox mobileHide>
                <Text>&copy;{getYear()}</Text>
              </FooterBox>
            </FooterCol>
            <FooterCol>
              <FooterBox>
                <DetailsRow>
                  <Label>Talk to Us</Label>
                  <Links>
                    <Link href="mailto:info@avprojets.com" primary>
                      info@avprojets.com
                    </Link>
                    <Link href="tel:14105731515">1.410.573.1515</Link>
                  </Links>
                </DetailsRow>
              </FooterBox>
              <FooterBox>
                <DetailsRow>
                  <Label>Connect with Us</Label>
                  <Links>
                    <Link
                      href="https://www.linkedin.com/company/avpro-inc./?viewAsMember=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn
                    </Link>
                    <Link href="https://www.facebook.com/avprojets/" target="_blank" rel="noopener noreferrer">
                      Facebook
                    </Link>
                    <Link href="https://www.twitter.com/AvproJets" target="_blank" rel="noopener noreferrer">
                      X
                    </Link>
                    <Link href="https://www.instagram.com/avprojets/" target="_blank" rel="noopener noreferrer">
                      Instagram
                    </Link>
                  </Links>
                </DetailsRow>
              </FooterBox>
              <FooterBox>
                <DetailsRow>
                  <Label>Certified</Label>
                  <Certifications>
                    <Certification src="../images/IADA.png" alt="IADA" />
                    <Certification src="../images/NBAA.png" alt="NBAA" />
                    <Certification src="../images/NAFA.png" alt="NAFA" />
                    <Certification src="../images/MEBAA.png" alt="MEBAA" />
                  </Certifications>
                </DetailsRow>
              </FooterBox>
            </FooterCol>
          </FooterRow>
        </SectionContent>
      </Wrapper>
    </Section>
  );
};

export default Footer;

const Section = styled(section)`
  z-index: 1;
  background: ${colors.white};
  padding: ${vars.spacing}px 0;

  @media ${mediaQuery.lg} {
    padding: ${vars.spacing * 1.5}px 0;
  }

  ${(props) =>
    props.footerUpgrade &&
    css`
      margin-top: auto;
      padding: ${vars.spacing / 2}px 0;

      @media ${mediaQuery.lg} {
        padding: ${vars.spacing / 2}px 0;
      }
    `}
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 965px) {
    flex-direction: row;
  }
`;

const FooterCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }

  @media (min-width: 965px) {
    & + & {
      margin-top: 0;
    }
    &:nth-of-type(1) {
      flex: 1 0 200px;
      max-width: 200px;
    }

    &:nth-of-type(2) {
      flex: 1 0 calc(100% - 200px);
      max-width: calc(100% - 200px);
      padding-left: 10%;
    }
  }
`;

const FooterBox = styled.div`
  display: ${(props) => (props.mobileHide ? "none" : "flex")};
  align-items: center;
  padding: 0;

  @media (min-width: 700px) {
    display: flex;
    padding: ${vars.gridGap}px ${vars.gridGap}px ${vars.gridGap}px 0;
    height: 75px;
    & + & {
      border-top: 1px solid ${colors.alto};
    }
  }
`;

const DetailsRow = styled.div`
  width: 100%;
  padding: ${vars.gridGap}px 0;

  @media (min-width: 700px) {
    display: flex;
    align-items: center;
    padding: 0;
  }
`;

const LogoBox = styled(link)`
  width: 143px;
  height: 38px;
  max-width: 65%;
  display: flex;

  svg {
    width: 100%;
    height: auto;
  }
`;

const Text = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 900,
    color: colors.makara,
    letterSpacing: "1px",
    lineHeight: 1.2,
    transform: "uppercase",
  })};
  max-width: 200px;
  margin: 0;
`;

const Label = styled.p`
  ${font({
    name: "realist",
    size: "16px",
    weight: 600,
    color: colors.mineShaft,
  })};
  margin: 0 0 ${vars.gridGap / 2}px;

  @media ${mediaQuery.md} {
    margin: 0;
  }
`;

const Certifications = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -${vars.gridGap * 0.75}px;

  @media (min-width: 700px) {
    align-items: center;
    flex-flow: row wrap;
    justify-content: center;
    margin-left: auto;
  }
`;

const Certification = styled.img`
  height: 16px;
  box-sizing: content-box;
  width: auto;
  object-fit: contain;
  padding: ${vars.gridGap * 0.75}px;

  p + & {
    height: 24px;
  }
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: -${vars.gridGap * 0.75}px;

  @media (min-width: 700px) {
    align-items: flex-end;
    flex-flow: row wrap;
    justify-content: center;
    margin-left: auto;
  }
`;

const Link = styled.a`
  ${font({
    name: "realist",
    size: "12px",
    weight: 900,
    color: colors.makara,
    letterSpacing: "1px",
    transform: "uppercase",
  })};
  margin: 0;
  box-sizing: content-box;
  padding: ${vars.gridGap * 0.75}px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.primary &&
    css`
      font-size: 17px;
      text-transform: none;
    `}
`;
