import * as React from "react";

import styled from "styled-components";

import type { WithWidthProps } from "../../../hoc/withWidth";
import { withWidth } from "../../../hoc/withWidth";

// const headerHeightPixels = 140;

const Content = styled("div")`
  background: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: "realistwide";
  font-size: 13pt;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const Graphs = (props: React.HTMLProps<HTMLDivElement>) => {
  return (
    <div
      {...props}
      style={{
        marginRight: "4rem",
        background: "rgb(60, 134, 163)",
        ...props.style,
      }}
    >
      <div>
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontStyle: "italic",
            fontFamily: "realistwide",
            fontSize: "12pt",
          }}
        >
          (Based on a Fixed Rate of 6.85%; 14 Year Market Amortization)
        </p>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {["Chart1.png", "Chart2.png", "Chart3.png", "Chart4.png"].map((imageName) => (
          <div style={{ width: "50%" }} key={imageName}>
            <img src={`../images/financial-services/${imageName}`} width="100%" height="100%" alt={imageName} />
          </div>
        ))}
      </div>
    </div>
  );
};

const StretchAmortization = (props: WithWidthProps) => {
  // const contentWidthPercent = props.isUp("md") ? 40 : 100;
  return (
    <div
      id="stretch-amortization"
      style={{
        position: "relative",
        // minHeight: "850px",
        fontFamily: "source-sans-pro",
        background: "url('../images/financial-services/Page 6 Image.jpg')",
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        color: "rgb(56,56,56)",
      }}
    >
      <div
        style={{
          // position: "flex",
          // top: 0,
          // height: `${headerHeightPixels}px`,
          // left: 0,
          // right: 0,
          textAlign: "left",
          fontSize: "18pt",
          paddingLeft: "4rem",
          paddingRight: "4rem",
        }}
      >
        <h2 style={{ fontFamily: "Selawik Semibold" }}>
          Stretch Amortization to Combat the Higher Rate Environment & Reinvest the Savings in Your Business
        </h2>
      </div>
      {props.isDown("lg") ? <Graphs style={{ margin: "4rem 2rem 4rem 4rem" }} /> : null}
      <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
        <div
          style={{
            paddingLeft: "4rem",
            paddingRight: "2rem",
            paddingBottom: "2rem",
          }}
        >
          <Content>
            <p>
              The savings noted {props.isUp("lg") ? "to the right" : "above"} are linear for the loan amounts; however,
              they are not the same when you compare the Amortization Gaps.
            </p>
            <p>
              The Balloon Payment Difference Gap is not large enough where the loan balance would likely exceed the Fair
              Market Value of the aircraft. This is not something that would have been recommended a few years ago, but
              today all lenders have Balloon adjustment covenants (paydowns) triggered by changes in value, tested
              either 2X or 3X during the term.
            </p>
            <p>
              If a lender were to call for a payment based on the appraisal process, you would have the right to
              refinance, further reducing your payments.
            </p>
            <p style={{ color: "rgb(234,51,35)" }}>
              The message is, save your cashflow for your day-to-day business and reinvest it where you get a return
              that far exceeds any reduction in Loan Balance.
            </p>
          </Content>
        </div>
        {props.isUp("lg") ? <Graphs style={{ minWidth: "850px" }} /> : null}
      </div>
    </div>
  );
};

export default withWidth(StretchAmortization);
