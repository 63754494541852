import React from "react";

import styled, { css } from "styled-components/macro";

import { colors, mediaQuery, Section } from "../../../helpers/styles";

const ServicesInterstitial = (props) => {
  return (
    <Section clearBottom={props.clearBottom} clearTop={props.clearTop}>
      <Interstitial src={props.sourceImage} alt="" mobilePosition={props.mobilePosition} />
    </Section>
  );
};

export default ServicesInterstitial;

const Interstitial = styled.img`
  object-fit: cover;
  width: 100%;
  height: 65vh;
  max-height: 1000px;
  display: block;
  background: ${colors.alto};

  ${(props) =>
    props.mobilePosition &&
    css`
      object-position: ${props.mobilePosition};

      @media ${mediaQuery.lg} {
        object-position: center;
      }
    `}
`;
