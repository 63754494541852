import React, { useEffect, useRef } from "react";

import { withRouter } from "react-router-dom";

import gsap from "gsap";
import styled from "styled-components/macro";

import { checkMediaQueryListener } from "../../helpers/scripts";
import { vars, colors, font } from "../../helpers/styles";
import { Icon, Alert } from "../Icons";

const Notification = (props) => {
  const notificationRef = useRef();

  const HtmlToReactParser = require("html-to-react").Parser;
  const htmlInput = props.notification.message_html;
  const htmlToReactParser = new HtmlToReactParser();
  const reactElement = htmlToReactParser.parse(htmlInput);

  const mql = checkMediaQueryListener("lg");
  const locPath = props.location.pathname;

  useEffect(() => {
    if (props.notification.is_visible) {
      gsap
        .timeline({
          delay: mql.matches ? 1 : 0,
        })
        .fromTo(
          notificationRef.current,
          { y: "-100%", autoAlpha: 0, marginBottom: "-62px" },
          {
            duration: 0.75,
            y: "0%",
            autoAlpha: 1,
            marginBottom: "0px",
          },
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notification, props.notification.is_visible]);

  if (props.notification.is_visible) {
    return (
      <NotificationBar scrolledNav={props.scrolledNav} location={locPath} ref={notificationRef}>
        <NotificationBlurbBox>
          <NotificationBlurbIcon>
            <Alert />
          </NotificationBlurbIcon>
          <NotificationBlurb>{reactElement}</NotificationBlurb>
        </NotificationBlurbBox>
      </NotificationBar>
    );
  }
  return null;
};

export default withRouter(Notification);

const NotificationBar = styled.div`
  position: relative;
  margin-top: 0;
  width: 100%;
  height: auto;
  background-color: ${colors.white};
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 -1px ${colors.tea};
  z-index: 10;
  transform: translateY(-100%);
  margin-bottom: -62px;
`;

const NotificationBlurbBox = styled.div`
  background-color: ${colors.white};
  padding: ${vars.gridGap}px ${vars.gridGap * 2.5}px;
  min-height: 62px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NotificationBlurbIcon = styled(Icon)`
  height: 20px;
  width: 20px;
  margin-right: ${vars.gridGap / 2}px;
  fill: ${colors.blueBayoux};
`;

const NotificationBlurb = styled.p`
  ${font({
    name: "realist",
    size: "16px",
    weight: 400,
    color: colors.black,
  })};
  margin: 0;

  span {
    ${font({
      color: colors.bermudaGray,
    })};
    cursor: pointer;
    text-decoration: underline;
  }
`;
