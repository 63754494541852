import React, { useState, useRef, useEffect } from "react";

import gsap from "gsap";
import styled from "styled-components/macro";

import OfferForm from "./OfferForm";
import { colors, hideText } from "../../../helpers/styles";
import { Close } from "../../Icons";

const OfferModal = (props) => {
  const modalRef = useRef();
  const modalScrimRef = useRef();
  const modalControlsRef = useRef();
  const modalCardRef = useRef();
  const formTitleRef = useRef();
  const formFieldsRef = useRef();
  const formListRef = useRef();
  const formButtonsRef = useRef();

  const [modalTween] = useState(
    gsap.timeline({
      paused: true,
      onStart: () => {
        // lock scroll
        props.toggleLockScroll(true);
      },
      onReverseComplete: () => {
        // hide modal
        props.setOfferModal(false);
        // unlock scroll
        props.toggleLockScroll(false);
      },
    }),
  );

  useEffect(() => {
    modalTween
      .to([modalScrimRef.current, modalControlsRef.current], {
        duration: 0.5,
        autoAlpha: 1,
        y: 0,
        stagger: 0.25,
      })
      .fromTo(
        modalCardRef.current,
        { autoAlpha: 0, y: 20 },
        {
          duration: 1,
          y: 0,
          autoAlpha: 1,
        },
        "-=0.25",
      )
      .from(
        [formTitleRef.current, formFieldsRef.current, formListRef.current, formButtonsRef.current],
        {
          duration: 0.75,
          y: 10,
          autoAlpha: 0,
          stagger: 0.15,
          onComplete: () => {
            // target first form field once completed
            formFieldsRef.current.firstChild.focus();
          },
        },
        "-=1",
      )
      .reverse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // close modal
  useEffect(() => {
    // scroll modal window back to top
    modalRef.current.scrollTop = 0;

    // run animation opening modal
    modalTween.timeScale(1).reversed(!props.offerModal);
  }, [modalRef, modalTween, props.offerModal]);

  // close modal on Esc
  useEffect(() => {
    const closeModalEsc = (e) => {
      if (props.offerModal && e.keyCode === 27) {
        close(e);
      }
    };

    window.addEventListener("keydown", closeModalEsc);

    // unmount
    return () => window.removeEventListener("keydown", closeModalEsc);
  });

  const close = (e) => {
    e.preventDefault();

    // run animation closing modal at 2x speed
    modalTween.timeScale(2).reversed(props.offerModal);
  };

  return (
    <Modal ref={modalRef} visible={props.offerModal}>
      <ModalScrim ref={modalScrimRef} onClick={close} />
      <ModalControls ref={modalControlsRef}>
        <ModalClose onClick={close}>
          Close
          <Close />
        </ModalClose>
      </ModalControls>
      <ModalCard ref={modalCardRef}>
        <OfferForm
          details={props.details}
          formTitleRef={formTitleRef}
          formFieldsRef={formFieldsRef}
          formListRef={formListRef}
          formButtonsRef={formButtonsRef}
          offerModal={props.offerModal}
          close={close}
        />
      </ModalCard>
    </Modal>
  );
};

export default OfferModal;

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${(props) => (props.visible ? "flex" : "none")};
  min-height: 100vh;
  width: 100%;
  z-index: 10;
  padding: 10vh 5vmax;
  overflow: auto;
`;

const ModalScrim = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(30, 30, 30, 0.9), #000 1810%);
  opacity: 0;
`;

const ModalControls = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  opacity: 0;
`;

const ModalClose = styled.button`
  ${hideText};
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(30, 30, 30, 0.9), #000 1810%);
  -webkit-tap-highlight-color: #0000;
  border: none;
  height: 45px;
  width: 45px;
  fill: #ddd;
  cursor: pointer;
  transition: fill 250ms ease;

  &:hover {
    fill: ${colors.white};
  }

  svg {
    height: 20px;
    width: 20px;
  }
`;

const ModalCard = styled.div`
  z-index: 1;
  margin: auto;
  max-width: 800px;
  width: 100%;
  background: #fff;
`;
