import React from "react";

import { Route, Switch } from "react-router-dom";

import { hot, setConfig } from "react-hot-loader";
import styled from "styled-components/macro";

import Footer from "./components/Footer";
import { Logo } from "./components/Icons";
import { GlobalStyle, vars, colors, font, mediaQuery, Wrapper, Section as section } from "./helpers/styles";

// Removes react-hot-loader console warning
setConfig({
  showReactDomPatchNotification: false,
});

const Upgrade = () => {
  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route path="/" exact>
          <Page>
            <Hero>
              <HeroLogo>
                <Logo />
              </HeroLogo>
              <Wrapper>
                <Section>
                  <Content>
                    <Title>Upgrade Your Browser</Title>
                    <Subtitle>
                      It looks like you’re using an outdated and unsupported web browser that is much more vulnerable to
                      security risks and performance issues. Please consider upgrading to one of the following modern
                      browsers in order to proceed.
                    </Subtitle>
                    <SuggestionsLabel>Suggested Modern Browsers</SuggestionsLabel>
                    <Suggestions>
                      <Suggestion href="https://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                        <SuggestionIcon src="../images/chrome.png" alt="" />
                        <SuggestionText>Google Chrome</SuggestionText>
                      </Suggestion>
                      <Suggestion
                        href="https://www.mozilla.org/en-US/firefox/new/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SuggestionIcon src="../images/firefox.png" alt="" />
                        <SuggestionText>Mozilla Firefox</SuggestionText>
                      </Suggestion>
                      <Suggestion
                        href="https://www.microsoft.com/en-us/windows/microsoft-edge"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <SuggestionIcon src="../images/microsoft-edge.png" alt="" />
                        <SuggestionText>Microsoft Edge</SuggestionText>
                      </Suggestion>
                    </Suggestions>
                  </Content>
                </Section>
              </Wrapper>
            </Hero>
            <Footer footerUpgrade />
          </Page>
        </Route>
      </Switch>
    </>
  );
};

export default hot(module)(Upgrade);

const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: ${colors.alabaster};
  margin-top: -${vars.mobileNavBar};
  max-width: 100vw;

  @media ${mediaQuery.lg} {
    margin-top: 0;
  }
`;

const Hero = styled.div`
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - 317px);
`;

const HeroLogo = styled.div`
  position: absolute;
  top: ${vars.gridGap * 2}px;
  left: ${vars.gridGap * 2}px;
  height: 35px;
  width: 134px;
`;

const Section = styled(section)`
  display: flex;
  padding: 140px 0;
  background: transparent;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850px;
  margin: auto;
`;

const Title = styled.h1`
  ${font({
    name: "ivymode",
    responsiveSize: "42px, 57px",
    weight: 200,
    color: colors.black,
    letterSpacing: "0.4px",
  })};
  text-align: center;
  width: 100%;
  margin: 0 0 ${vars.gridGap}px;
`;

const Subtitle = styled.p`
  ${font({
    name: "realist",
    responsiveSize: "16px 20px",
    weight: 400,
    color: colors.black,
    letterSpacing: "0.5px",
  })};
  position: relative;
  margin: 0 0 ${vars.gridGap * 3}px;
  text-align: center;
  width: 100%;
`;

const SuggestionsLabel = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 800,
    color: colors.makara,
    letterSpacing: "1px",
    transform: "uppercase",
  })};
  margin: 0 0 ${vars.gridGap}px;
`;

const Suggestions = styled.div`
  display: flex;
`;

const Suggestion = styled.a`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${vars.gridGap}px;
  text-decoration: none;
`;

const SuggestionIcon = styled.img`
  display: block;
  object-fit: contain;
  height: 50px;
  width: 50px;
  margin: 0 auto ${vars.gridGap / 2}px;
`;

const SuggestionText = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 200,
    color: colors.makara,
    letterSpacing: "0.5px",
  })};
  margin: 0;
  text-align: center;
`;
