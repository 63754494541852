import React, { useState, useRef } from "react";

import queryString from "query-string";

import { removeParamFromURL, updateURL } from "../../../hooks";
import {
  OptionHeader,
  OptionTitle,
  OptionClear,
  Options,
  Option,
  OptionInput,
  OptionLabel,
} from "../../global/Options";

const FilterRange = (props) => {
  const { history, location, scrollToMain } = props;
  const radioRef1 = useRef();
  const radioRef2 = useRef();
  const radioRef3 = useRef();

  const [optionSelected, setOptionSelected] = useState(false);

  const g = queryString.parse(location.search);

  const handleClear = (e) => {
    e.preventDefault();
    setOptionSelected(false);

    radioRef1.current.checked = false;
    radioRef2.current.checked = false;
    radioRef3.current.checked = false;

    const remove = removeParamFromURL("airframe_total_time", g);
    history.push(`/${remove}`);
  };

  const handleRange = (e) => {
    const newURL = updateURL(g, "airframe_total_time", location.search, e.target.value);

    setOptionSelected(true);

    history.replace(`/${newURL}`);

    scrollToMain();
  };

  return (
    <>
      <OptionHeader>
        <OptionTitle>Airframe Hours</OptionTitle>
        <OptionClear optionSelected={optionSelected} onClick={(e) => handleClear(e)}>
          Clear
        </OptionClear>
      </OptionHeader>
      <Options>
        <Option>
          <OptionInput
            type="radio"
            name="airframe"
            id="airframe1"
            ref={radioRef1}
            onClick={(e) => handleRange(e)}
            value="isLess-4000"
          />
          <OptionLabel
            htmlFor="airframe1"
            isActive={!!(g.airframe_total_time && g.airframe_total_time === "isLess-4000")}
          >
            {"<4k"}
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type="radio"
            name="airframe"
            id="airframe2"
            ref={radioRef2}
            onClick={(e) => handleRange(e)}
            value="4000-8000"
          />
          <OptionLabel
            htmlFor="airframe2"
            isActive={!!(g.airframe_total_time && g.airframe_total_time === "4000-8000")}
          >
            4k-8k
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type="radio"
            name="airframe"
            id="airframe3"
            ref={radioRef3}
            onClick={(e) => handleRange(e)}
            value="isGreater-8000"
          />
          <OptionLabel
            htmlFor="airframe3"
            isActive={!!(g.airframe_total_time && g.airframe_total_time === "isGreater-8000")}
          >
            8k+
          </OptionLabel>
        </Option>
      </Options>
    </>
  );
};

export default FilterRange;
