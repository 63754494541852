import React, { useRef, useState } from "react";

import axios from "axios";
import Cleave from "cleave.js/react";
import gsap from "gsap";
import { darken, lighten } from "polished";
import styled, { css } from "styled-components/macro";
import validator from "validator";

import { vars, colors, font, mediaQuery } from "../../../helpers/styles";
import { Close } from "../../Icons";

let apiDomain = process.env.REACT_APP_PROD_API;

if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  apiDomain = process.env.REACT_APP_DEV_API;
}
const OfferForm = (props) => {
  const [validForm, setValidForm] = useState(false);
  const [emailError, setEmailError] = useState("");
  const boxRef = useRef();
  const formContainerRef = useRef();
  const submittedContainerRef = useRef();
  const formRef = useRef();
  const nameRef = useRef();
  const titleRef = useRef();
  const emailRef = useRef();
  const offerPriceRef = useRef();
  const depositRef = useRef();
  const agreementRef = useRef();
  const { details } = props;

  const handleChange = (event) => {
    event.preventDefault();
    if (!validator.isEmail(emailRef.current.value)) {
      setEmailError("Email is not valid");
    } else {
      setEmailError("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      buyer_name: nameRef.current.value,
      job_title: titleRef.current.value,
      email: emailRef.current.value,
      aircraft: details.id,
      price: parseInt(offerPriceRef.current.lastInputValue.replace(/,/g, "")),
      deposit: parseInt(depositRef.current.lastInputValue.replace(/,/g, "")),
    };

    axios({
      method: "post",
      url: `${apiDomain}/offers`,
      data,
    })
      .then(function () {
        submitSuccess();
      })
      .catch(function (error) {
        // console.log(error.response.data)
      });
  };

  const submitSuccess = () => {
    gsap
      .timeline()
      .to(
        boxRef.current,
        {
          duration: 0.5,
          height: submittedContainerRef.current.clientHeight,
          overflow: "hidden",
          clearProps: "overflow",
        },
        0,
      )
      .to(
        formContainerRef.current,
        {
          duration: 0.5,
          y: 20,
          autoAlpha: 0,
          display: "none",
        },
        0,
      )
      .set(submittedContainerRef.current, {
        position: "relative",
        autoAlpha: 1,
      })
      .fromTo(
        submittedContainerRef.current.children,
        {
          autoAlpha: 0,
          y: 20,
        },
        {
          duration: 0.5,
          autoAlpha: 1,
          y: 0,
          stagger: 0.15,
        },
      )
      .set(boxRef.current, { clearProps: "all" });
  };

  // Successful submit screen
  const submitContent = (
    <SubmitContainer ref={submittedContainerRef}>
      <SubmitTitle>Offer Successfully Received!</SubmitTitle>
      <SubmitText>We'll get back to you as soon as possible.</SubmitText>
      <SubmitButton onClick={(e) => props.close(e)}>Close</SubmitButton>
    </SubmitContainer>
  );

  return (
    <Content>
      <Box ref={boxRef}>
        {submitContent}
        <FormContainer ref={formContainerRef}>
          <Form
            ref={formRef}
            method="POST"
            onSubmit={handleSubmit}
            name="Offer Form"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            <input type="hidden" name="" value="offer" />
            <input type="hidden" name="form-name" value="offer" />
            <Title ref={props.formTitleRef}>Make an Offer</Title>
            <FormFields ref={props.formFieldsRef}>
              <Label colSize="third" required>
                <LabelText>Buyer Name</LabelText>
                <Input ref={nameRef} name="name" type="text" placeholder="John Doe" required autoFocus />
              </Label>
              <Label colSize="third" required>
                <LabelText>Job Title</LabelText>
                <Input ref={titleRef} name="title" type="text" placeholder="President, CEO" required />
              </Label>
              <Label colSize="third" required>
                <LabelText>Email</LabelText>
                {emailError && <ErrorFormField>{emailError}</ErrorFormField>}
                <Input
                  ref={emailRef}
                  name="email"
                  type="email"
                  placeholder="JohnDoe@email.com"
                  required
                  onChange={handleChange}
                />
              </Label>
              <Label colSize="third">
                <LabelText>manufacturer</LabelText>
                <Input type="text" name="manufacturer" placeholder="Gulfstream" disabled value={details.manufacturer} />
              </Label>
              <Label colSize="third">
                <LabelText>Model</LabelText>
                <Input type="text" name="model" placeholder="G650ER" disabled value={details.model} />
              </Label>
              <Label colSize="third">
                <LabelText>Serial Number</LabelText>
                <Input type="text" name="serial number" placeholder="6240" disabled value={details.serial_number} />
              </Label>
              <Label colSize="half" required>
                <LabelText>Offer Price</LabelText>
                <CurrencyInput
                  ref={offerPriceRef}
                  options={{
                    numeral: true,
                  }}
                  name="offer price"
                  type="text"
                  placeholder={
                    details.display_price !== undefined &&
                    details.display_price !== "" &&
                    details.display_price !== null
                      ? details.display_price
                      : details.price
                  }
                  required
                />
                <CurrencyIcon>$</CurrencyIcon>
              </Label>
              <Label colSize="half" required>
                <LabelText>Deposit</LabelText>
                <CurrencyInput
                  ref={depositRef}
                  options={{
                    numeral: true,
                  }}
                  name="deposit"
                  type="text"
                  placeholder=""
                  required
                />
                <CurrencyIcon>$</CurrencyIcon>
              </Label>
              <Label hiddenField>
                Don’t fill this out if you're human
                <Input name="bot-field" type="text" placeholder="Hello fellow humans" />
              </Label>
            </FormFields>

            <FormList ref={props.formListRef}>
              <FormListSection>
                Purchase Price
                <FormListText>Purchase price shall be payable at time of Aircraft delivery.</FormListText>
              </FormListSection>
              <FormListSection>
                Inspection
                <FormListText>
                  Buyer shall have the right to conduct a visual inspection of the Aircraft and its records at a
                  mutually agreeable location as soon as practical. If found acceptable, Buyer shall have the right to
                  conduct a pre-purchase inspection, of a mutually agreeable scope, (the “Inspection”) and test-flight
                  of the Aircraft, at a mutually agreeable inspection facility (the “Inspection Facility”) to verify the
                  condition of the Aircraft. Buyer shall be responsible for the cost of the Inspection and test-flight.
                  Seller shall be responsible or the correction of airworthiness items and other discrepancies
                  identified by the Inspection Facility during the Inspection and test flight required to make the
                  Aircraft conform to the condition specified in paragraph 3 below.
                </FormListText>
              </FormListSection>
              <FormListSection>
                Condition
                <FormListText>
                  The Aircraft shall be delivered free and clear of all liens or encumbrances, with a valid Certificate
                  of Airworthiness, in an airworthy condition, with all systems functioning in accordance with the
                  manufacturer’s specifications, current on the manufacturer’s recommended maintenance program, with all
                  Airworthiness Directives and Mandatory Service Bulletins complied with, and current on any applicable
                  maintenance service programs that the aircraft is currently enrolled on that relate to engines.
                </FormListText>
              </FormListSection>
              <FormListSection>
                Delivery
                <FormListText>
                  Following completion of the inspection and correction of discrepancies, the aircraft shall be
                  delivered at the Inspection Facility or other mutually agreeable location within 3 business days.
                </FormListText>
              </FormListSection>
              <FormListSection>
                Purchase and Sale Agreement
                <FormListText>
                  This offer and the obligations of Buyer and Seller herein are not binding upon either party and are
                  subject to the negotiation and execution of a mutually agreeable definitive Purchase and Sale
                  Agreement. This Purchase and Sale agreement shall be executed within ten (10) business days from
                  seller’s acceptance of this offer.
                </FormListText>
              </FormListSection>
              <FormListSection>
                Deposit
                <FormListText>
                  Upon acceptance of this offer, Buyer shall place a refundable deposit in escrow with Insured Aircraft
                  Title Service Company, Oklahoma City, OK to be held pending execution of a Purchase and Sale Agreement
                  and governed thereafter by said agreement.
                </FormListText>
                <FormListText>
                  This offer is valid through 5:00pm Eastern Standard Time, 2 business days after the date of the offer
                  after which, if not accepted, shall expire having no further force or effect.
                </FormListText>
              </FormListSection>
            </FormList>
            <ButtonRow ref={props.formButtonsRef}>
              <CheckboxLabel>
                <CheckboxInput
                  ref={agreementRef}
                  type="checkbox"
                  onClick={() => {
                    setValidForm(agreementRef.current.checked);
                  }}
                />
                <Checkbox>
                  <Close />
                </Checkbox>
                <CheckboxText>
                  Check here to indicate that you have read and agree to the terms of this non-binding offer.
                </CheckboxText>
              </CheckboxLabel>
              <Button type="submit" disabled={!validForm}>
                Submit Request
              </Button>
            </ButtonRow>
          </Form>
        </FormContainer>
      </Box>
    </Content>
  );
};

export default OfferForm;

const Content = styled.div`
  padding: ${vars.spacing * 0.75}px ${vars.spacing / 2}px;
  position: relative;
  background-color: ${colors.alabaster};
  width: 100%;

  @media ${mediaQuery.lg} {
    padding: ${vars.spacing}px;
  }
`;

const Box = styled.div`
  position: relative;
`;

const SubmitContainer = styled.div`
  position: absolute;
  opacity: 0;
`;

const FormContainer = styled.div``;

const Title = styled.p`
  ${font({
    name: "realist",
    responsiveSize: "20px, 34px",
    weight: 600,
    color: colors.tapa,
    letterSpacing: "0.5px",
    lineHeight: 1,
  })};
  margin: ${vars.gridGap / 2}px 0 ${vars.gridGap * 2}px ${vars.gridGap / 2}px;
`;

const SubmitTitle = styled(Title)`
  margin: 0 0 ${vars.gridGap}px;
`;

const SubmitText = styled.p`
  ${font({
    name: "realist",
    size: "16px",
    weight: 400,
    color: colors.tundora,
    lineHeight: 1.6,
  })};
  margin: 0 0 ${vars.gridGap * 2}px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: -${vars.gridGap / 2}px -${vars.gridGap / 2}px 0;
`;

const FormFields = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ErrorFormField = styled.span`
  font-size: 12px;
  color: red;
`;

const Label = styled.label`
  flex-direction: column;
  position: relative;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  display: flex;
  padding: ${vars.gridGap / 2}px ${vars.gridGap / 2}px;

  ${(props) =>
    props.colSize === "half" &&
    css`
      @media ${mediaQuery.sm} {
        max-width: 50%;
      }
    `}

  ${(props) =>
    props.colSize === "third" &&
    css`
      @media ${mediaQuery.sm} {
        max-width: 50%;
      }
      @media ${mediaQuery.md} {
        max-width: 33.33333%;
      }
    `}

  ${(props) =>
    props.required &&
    css`
      ${LabelText}::after {
        font-size: 10px;
        color: ${colors.carnation};
        margin: 0 0 0 ${vars.gridGap / 4}px;
        transform: translateY(-${vars.gridGap / 8}px);
        display: inline-block;
        content: "*";
      }
    `}

  ${(props) =>
    props.hiddenField &&
    css`
      position: absolute;
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
    `}
`;

const LabelText = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 800,
    color: colors.tapa,
    transform: "uppercase",
    letterSpacing: "1px",
  })};
  margin: 0;
`;

const Input = styled.input`
  ${font({
    name: "realist",
    size: "14px",
    weight: 400,
    color: colors.mineShaft,
  })};
  display: block;
  width: 100%;
  height: 48px;
  margin-top: ${vars.gridGap / 2}px;
  padding: ${vars.gridGap}px;
  background: ${colors.white};
  border: 0;
  outline: none;
  box-shadow:
    inset 0 0 0 1px ${colors.alto},
    0 0 10px rgba(0, 0, 0, 0.05);
  transition: 250ms;

  &::placeholder {
    color: ${lighten(0.6, colors.mineShaft)};
  }

  &:focus,
  &:hover {
    box-shadow:
      inset 0 0 0 2px ${colors.tea},
      0 0 10px rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    background: ${colors.alabaster};
    color: ${lighten(0.5, colors.mineShaft)};

    &:focus,
    &:hover {
      box-shadow:
        inset 0 0 0 1px ${colors.alto},
        0 0 10px rgba(0, 0, 0, 0.05);
    }
  }
`;

const CurrencyInput = styled(Cleave)`
  ${font({
    name: "realist",
    size: "14px",
    weight: 400,
    color: colors.mineShaft,
  })};
  position: relative;
  display: block;
  width: 100%;
  height: 48px;
  margin-top: ${vars.gridGap / 2}px;
  padding: ${vars.gridGap}px;
  background: ${colors.white};
  border: 0;
  outline: none;
  box-shadow:
    inset 0 0 0 1px ${colors.alto},
    0 0 10px rgba(0, 0, 0, 0.05);
  transition:
    box-shadow 250ms,
    color 250ms;

  &:not(:placeholder-shown) {
    padding-left: 26px;
  }

  &::placeholder {
    color: ${lighten(0.6, colors.mineShaft)};
  }

  &:focus,
  &:hover {
    box-shadow:
      inset 0 0 0 2px ${colors.tea},
      0 0 10px rgba(0, 0, 0, 0.05);
  }

  &:disabled {
    background: ${colors.alabaster};
    color: ${lighten(0.5, colors.mineShaft)};

    &:focus,
    &:hover {
      box-shadow:
        inset 0 0 0 1px ${colors.alto},
        0 0 10px rgba(0, 0, 0, 0.05);
    }
  }
`;

const CurrencyIcon = styled.span`
  ${font({
    name: "realist",
    size: "14px",
    weight: 400,
    color: colors.mineShaft,
  })};
  position: absolute;
  left: 24px;
  bottom: 23px;
  opacity: 0;

  ${CurrencyInput}:not(:placeholder-shown) + & {
    opacity: 1;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-self: flex-end;
  width: 100%;
  padding: ${vars.gridGap / 2}px ${vars.gridGap / 2}px 0;
`;

const Button = styled.button`
  ${font({
    name: "realist",
    size: "12px",
    weight: 600,
    color: colors.black,
    letterSpacing: "1px",
    transform: "uppercase",
  })};
  align-self: flex-end;
  background-color: ${colors.tea};
  padding: ${vars.gridGap * 0.75}px ${vars.gridGap * 2}px;
  text-align: center;
  transition:
    background-color 250ms ease,
    color 250ms ease;
  cursor: pointer;
  margin: 0 0 0 auto;
  border: none;

  &:hover {
    background: ${darken(0.06, colors.tea)};
  }

  &:disabled {
    cursor: initial;
    background-color: ${colors.timberwolf};
    color: ${colors.gray};
  }

  &:focus {
    background-color: ${colors.makara};
    color: ${colors.white};
  }
`;

const SubmitButton = styled(Button)`
  margin-left: auto;
`;

const FormList = styled.ol`
  margin: ${vars.gridGap * 2}px 0;
  padding-left: ${vars.gridGap * 1.5}px;
`;

const FormListSection = styled.li`
  ${font({
    name: "realist",
    size: "12px",
    weight: 800,
    color: colors.tapa,
    transform: "uppercase",
    letterSpacing: "1px",
  })};
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap * 1.5}px;
  }
`;

const FormListText = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 400,
    color: colors.tundora,
    lineHeight: 1.6,
  })};
  margin: ${vars.gridGap / 2}px 0 0;
  text-transform: initial;

  & + & {
    margin: ${vars.gridGap}px 0 0;
  }
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: ${vars.gridGap * 2}px;
  max-width: 350px;
  width: 100%;
`;

const CheckboxInput = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 16px;
  width: 16px;
  box-shadow:
    0 0 0 1px ${colors.alto},
    0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  background: ${colors.white};
  fill: ${colors.alabaster};
  transition: 300ms;
  cursor: pointer;
  margin-left: 2px;

  svg {
    opacity: 0;
    transition: opacity 300ms;
    width: 14px;
    height: 14px;
  }

  ${CheckboxInput}:checked ~ & {
    background: ${colors.bermudaGray};
    box-shadow:
      0 0 0 1px ${colors.bermudaGray},
      0 0 10px rgba(0, 0, 0, 0.05);

    svg {
      opacity: 1;
    }
  }

  ${CheckboxLabel} ${CheckboxInput}:focus ~ &,
  ${CheckboxInput} ~ &:hover {
    background: ${colors.alto};
    box-shadow:
      0 0 0 1px ${colors.tea},
      0 0 10px rgba(0, 0, 0, 0.05);
  }

  ${CheckboxInput}:checked:focus ~ &,
  ${CheckboxInput}:not([disabled]):checked ~ &:hover {
    background-color: ${colors.bermudaGray};
    box-shadow:
      0 0 0 2px ${darken(0.125, colors.bermudaGray)},
      0 0 10px rgba(0, 0, 0, 0.05);

    svg {
      opacity: 1;
    }
  }
`;

const CheckboxText = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 400,
    color: colors.tundora,
    lineHeight: 1.4,
  })};
  margin: 0 0 0 ${vars.gridGap / 2}px;
`;
