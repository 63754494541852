import React from "react";

import styled from "styled-components/macro";

import { vars, colors, font, mediaQuery } from "../../../helpers/styles";
import { Passenger, Bed } from "../../Icons";

class Occupancy extends React.Component {
  test = () => {
    return Array.from({ passengers: this.props.passengers }, (item, index) => (
      <PassengerIcon key={`passenger-${index}`}>
        <Passenger />
      </PassengerIcon>
    ));
  };

  render() {
    // Get number of passenger icons
    const passengers = [];
    if (this.props.passengers) {
      if (this.props.passengers <= 15) {
        for (let i = 0; i < this.props.passengers; i++) {
          passengers.push(
            <PassengerIcon key={`passenger-${i}`}>
              <Passenger />
            </PassengerIcon>,
          );
        }
      } else {
        passengers.push(
          <PassengerIcon key="passenger-1">
            <Passenger />
          </PassengerIcon>,
        );
      }
    }

    // Get number of bed icons
    const beds = [];
    if (this.props.beds) {
      if (
        // check if value has a '+'
        this.props.beds.split("+").length === 1 &&
        // or a '-'
        this.props.beds.split("-").length === 1 &&
        this.props.beds <= 6
      ) {
        for (let i = 0; i < this.props.beds; i++) {
          beds.push(
            <BedIcon key={`bed-${i}`}>
              <Bed />
            </BedIcon>,
          );
        }
      } else {
        beds.push(
          <BedIcon key="bed-1">
            <Bed />
          </BedIcon>,
        );
      }
    }

    return (
      <Box>
        <Row>
          <PassengerNumber>{this.props.passengers}</PassengerNumber>
          <Icons>{passengers}</Icons>
        </Row>
        {this.props.beds ? (
          <Row>
            <BedNumber>({this.props.beds})</BedNumber>
            <Icons>{beds}</Icons>
          </Row>
        ) : null}
      </Box>
    );
  }
}

export default Occupancy;

const Box = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: ${vars.gridGap}px;
  width: 100%;

  @media ${mediaQuery.md} {
    justify-content: center;
    flex-direction: column;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-right: ${vars.gridGap * 2}px;
  }
`;

const PassengerNumber = styled.p`
  ${font({
    name: "realist",
    responsiveSize: "40px, 57px",
    weight: 400,
    color: colors.white,
    letterSpacing: "-0.2px",
  })};
  margin: 0 ${vars.gridGap / 2}px 0 0;

  @media ${mediaQuery.md} {
    margin: 0 ${vars.gridGap}px 0 0;
  }
`;

const Icons = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;

const PassengerIcon = styled.div`
  padding: ${vars.gridGap / 4}px;

  &:not(:first-of-type) {
    display: none;
  }

  svg {
    width: 13px;
    height: 31px;
    fill: ${colors.white};
  }

  @media ${mediaQuery.md} {
    &:not(:first-of-type) {
      display: block;
    }

    svg {
      width: 17px;
      height: 43px;
    }
  }
`;

const BedNumber = styled.p`
  ${font({
    name: "realist",
    responsiveSize: "30px, 38px",
    weight: 400,
    color: colors.bermudaGray,
    letterSpacing: "-0.2px",
  })};
  margin: 0 ${vars.gridGap / 2}px 0 0;
  white-space: nowrap;

  @media ${mediaQuery.md} {
    margin: 0 ${vars.gridGap}px 0 0;
  }
`;

const BedIcon = styled.div`
  padding: ${vars.gridGap / 4}px;

  &:not(:first-of-type) {
    display: none;
  }

  svg {
    width: 40px;
    height: 31px;
    fill: ${colors.bermudaGray};
  }

  @media ${mediaQuery.md} {
    &:not(:first-of-type) {
      display: block;
    }

    svg {
      width: 50px;
      height: 39px;
    }
  }
`;
