import * as React from "react";

import styled from "styled-components";

import type { WithWidthProps } from "../../../hoc/withWidth";
import { withWidth } from "../../../hoc/withWidth";

const Content = styled("div")`
  > div {
    margin-bottom: 2rem;
  }
`;

const ShouldIConsiderNewLender = (props: WithWidthProps) => {
  const shouldShowImage = props.isUp("md");
  return (
    <div
      id="should-i-consider-new-lender"
      style={{
        position: "relative",
        minHeight: "850px",
        fontFamily: "source-sans-pro",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {shouldShowImage && (
        <div
          style={{
            background: "url('../images/financial-services/Page 4 Image.jpg')",
            backgroundSize: "cover",
            flex: 1,
          }}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div style={{ padding: "2rem" }}>
          <h2 style={{ fontSize: "20pt", fontFamily: "realistwide", textAlign: "center" }}>
            SHOULD I CONSIDER FINANCING MY AIRCRAFT PURCHASE WITH A NEW LENDER?
          </h2>
        </div>
        <div style={{ width: "100%", background: "rgb(60, 134, 163)", height: "20pt" }} />
        <Content
          style={{
            fontFamily: "realistwide",
            paddingLeft: "4rem",
            paddingRight: "4rem",
            paddingTop: "2rem",
            paddingBottom: "2rem",
            fontSize: "13pt",
            lineHeight: 1.44,
          }}
        >
          <div
            style={{ fontStyle: "Selawik Semibold", fontSize: "20pt", fontWeight: "bold", color: "rgb(30, 71, 112)" }}
          >
            THE ANSWER IS{" "}
            <span style={{ textDecoration: "underline" }}>
              <em>YES</em>
            </span>
            , AND HERE’S WHY...
          </div>
          <div>
            <b>Y</b>our primary lender knows you best, has helped you grow your business, and should remain your
            preferred lender for opportunities to expand your business. But their capacity is limited, as is every bank,
            by:
            <ul style={{ listStylePosition: "inside", padding: 0, marginTop: 0 }}>
              <li>Their risk and credit criteria</li>
              <li>Level of comfort with certain industries and exposure to them</li>
              <li>And your specific credit profile and exposure</li>
            </ul>
          </div>
          <div>
            <b>A</b>ircraft are long lived, and from a bank’s perspective, non-income producing assets. Therefore, your
            loan balance remains high, and the banks capacity to lend to you restricted by the size of their exposure to
            you and the “sticky” nature of the aircraft loan balance.
          </div>
          <div>
            <b>I</b>n any economic environment access to capital is key to growth. Aircraft lenders are typically larger
            institutions who can provide that additional capital your business needs to continue to grow.
          </div>
          <div>
            <b>B</b>y bringing your aircraft to a new lender, you preserve your capacity with your primary lender and
            establish a new relationship with a lender who desires an expanded relationship with you and your company
            and can provide capacity to further your growth objectives uninterrupted.
          </div>
        </Content>
      </div>
    </div>
  );
};

export default withWidth(ShouldIConsiderNewLender);
