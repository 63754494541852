import React from "react";

import styled from "styled-components/macro";

import {
  vars,
  colors,
  font,
  mediaQuery,
  Wrapper,
  Section as section,
  SectionTitle as sectionTitle,
  SectionText,
} from "../../../helpers/styles";

const CompanyKnowledge = (props) => {
  return (
    <Section id="knowledge">
      <Wrapper>
        <SectionTitle>Knowledge</SectionTitle>
        <Feature>
          <FeatureText>
            Avpro is the center of gravity for the industry’s most powerful resource: accurate and current market
            information. We know and project the value of business aircraft with an extremely high degree of fidelity –
            and we harness that information with the mindset of owners and traders.
          </FeatureText>
        </Feature>
        <Grid>
          <GridText>
            We are successful because we help buyers and sellers understand the intricacies of a complicated market
            while keeping our eyes on the big picture. We overlay our knowledge of the market with a forecast of larger
            economic trends.
          </GridText>
          <GridText>
            Our intel engine is constant and organized around data management and analysis. We’re engaged in a
            continuous cycle of learning, and we’re further engaged in a continuous search for{" "}
            <strong>actionable intelligence</strong> as the basis for decisions.
          </GridText>
        </Grid>
      </Wrapper>
    </Section>
  );
};

export default CompanyKnowledge;

const Section = styled(section)`
  background-color: ${colors.mineShaft};
`;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const Feature = styled.div`
  border-top: 1px solid ${colors.bermudaGray};
  border-bottom: 1px solid ${colors.bermudaGray};
  padding: ${vars.gridGap * 3}px 0;
`;

const FeatureText = styled.p`
  ${font({
    name: "ivymode",
    responsiveSize: "24px, 32px",
    weight: 200,
    color: colors.white,
    lineHeight: 1.5,
  })};
  margin: 0;

  & + & {
    margin-top: ${vars.gridGap * 2}px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${vars.gridGap * 2}px;
  margin-top: ${vars.gridGap * 3}px;

  @media ${mediaQuery.md} {
    grid-template-columns: 1fr 1fr;
  }
`;

const GridText = styled(SectionText)`
  margin: 0;
  color: ${colors.white};
`;
