import React from "react";

import Observer from "@researchgate/react-intersection-observer";
import styled from "styled-components/macro";

import { vars, colors, font, mediaQuery, Wrapper, Section as section } from "../../../helpers/styles";

const CompanyHero = (props) => {
  const handleIntersection = (e) => {
    props.toggleScrolledNav(!e.isIntersecting);
  };

  return (
    <Observer onChange={handleIntersection} rootMargin="0px 0px 0px">
      <Hero>
        <HeroBackground>
          <HeroBackgroundImage src="../images/hero-company.svg" />
        </HeroBackground>
        <Wrapper>
          <Section>
            <HeroContent>
              <HeroTitle>Welcome to Avpro</HeroTitle>
              <HeroSubtitle>
                We exclusively represent clients seeking to buy, sell, and trade new and preowned business aircraft. We
                routinely transact 100 aircraft per year worth more than one billion dollars.
              </HeroSubtitle>
            </HeroContent>
          </Section>
        </Wrapper>
      </Hero>
    </Observer>
  );
};

export default CompanyHero;

const Section = styled(section)`
  display: flex;
  padding: 40px 0 140px;
  background: transparent;

  @media ${mediaQuery.sm} {
    padding: 140px 0;
  }
`;

const Hero = styled.header`
  min-height: calc(100vh - ${vars.mobileNavBar});
  overflow: hidden;
  position: relative;

  @media ${mediaQuery.sm} {
    min-height: 100vh;
  }
`;

const HeroBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: ${colors.timberwolf};
  display: flex;
`;

const HeroBackgroundImage = styled.img`
  width: 100%;
  height: 60%;
  object-fit: cover;
  object-position: 40%;
  transform: translateY(30%);
  margin-top: auto;

  @media ${mediaQuery.md} {
    transform: translateY(40%);
    height: 100%;
  }

  @media ${mediaQuery.lg} {
    transform: translateY(35%);
    height: 100%;
  }
`;

const HeroContent = styled.div`
  flex: 1 0 auto;
  max-width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeroTitle = styled.h1`
  ${font({
    name: "ivymode",
    responsiveSize: "40px, 57px",
    weight: 200,
    color: colors.black,
    letterSpacing: "0.4px",
  })};
  margin: ${vars.gridGap}px 0;
`;

const HeroSubtitle = styled.h1`
  ${font({
    name: "realist",
    responsiveSize: "20px, 25px",
    weight: 400,
    color: colors.black,
    letterSpacing: "0.2px",
  })};
  margin: 0;
`;
