import React, { useState, useEffect } from "react";

import queryString from "query-string";
import styled from "styled-components/macro";

import { vars, colors, font } from "../../../helpers/styles";
import { removeParamFromURL, updateURL } from "../../../hooks";
import { OptionHeader, OptionTitle } from "../../global/Options";

const FilterYear = (props) => {
  const { history, location, scrollToMain } = props;

  const [count, setCount] = useState("");

  const validate = (val) => {
    if (val && val.match(/^0|[0-9]\d*$/)) {
      setCount(val);
    } else if (val.length === 0) {
      setCount("");
    }
  };

  const g = queryString.parse(location.search);

  const oldestPlane = 1982;

  useEffect(() => {
    if (g.year_model) {
      const filteredCount = g.year_model.replace(`${oldestPlane}-`, "");
      setCount(filteredCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    validate(e.target.value);
    if (e.target.value !== "" && e.target.value.length === 4) {
      const add = updateURL(g, "year_model", location.search, `${oldestPlane}-${e.target.value}`);
      history.replace(add);
      scrollToMain();
    } else {
      const remove = removeParamFromURL("year_model", queryString.parse(location.search));
      history.replace(`/${remove}`);
    }
  };

  return (
    <Row>
      <Box>
        <OptionHeader>
          <OptionTitle>Year</OptionTitle>
        </OptionHeader>
        <Year>{oldestPlane}</Year>
      </Box>
      <Box>
        <Text>To</Text>
      </Box>
      <Box>
        <OptionHeader>
          <OptionTitle>End Year</OptionTitle>
        </OptionHeader>
        <Input type="text" onChange={(e) => handleChange(e)} maxLength="4" value={count} placeholder="2020" />
      </Box>
    </Row>
  );
};

export default FilterYear;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Box = styled.div`
  max-width: 100px;
`;

const Year = styled.p`
  ${font({
    name: "realist",
    size: "18px",
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  margin: 0;
`;

const Text = styled.p`
  ${font({
    name: "realist",
    size: "12px",
    weight: 900,
    color: colors.tapa,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  margin: ${vars.gridGap}px 0 0;
`;

const Input = styled.input`
  ${font({
    name: "realist",
    size: "18px",
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  width: 100%;
  border: none;
  outline: none;
  background: transparent;

  &::placeholder {
    color: ${colors.tea};
  }
`;
