import * as React from "react";

import styled from "styled-components";

import type { WithWidthProps } from "../../../hoc/withWidth";
import { withWidth } from "../../../hoc/withWidth";

const Content = styled("div")`
  max-width: 1100px;
  margin: auto;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 2rem;
  padding-top: 2rem;

  p {
    font-family: "realistwide";
    font-size: 13pt;
  }

  > div {
    text-align: left;
    margin-bottom: 2rem;
    background: rgba(255, 255, 255, 0.9);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const TheFinancialAdvisoryProcess = (props: WithWidthProps) => {
  return (
    <div
      id="the-financial-advisory-process"
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minHeight: "850px",
        fontFamily: "source-sans-pro",
        background: "url('../images/financial-services/Page 5 Image.jpg')",
        backgroundSize: "cover",
        color: "rgb(56,56,56)",
      }}
    >
      <div
        style={{
          textAlign: "center",
          fontSize: props.isUp("md") ? "36pt" : "24pt",
          paddingTop: "4rem",
        }}
      >
        <h2 style={{ fontFamily: "Selawik Semibold", margin: 0 }}>The Financial Advisory Process</h2>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <div style={{ position: "relative", height: "100%", width: "100%" }}>
          <Content>
            <div>
              <p>
                An initial discussion of 30 minutes or less between you and Don Synborski will cover the aircraft to be
                financed, it’s mission needs, and the legal/corporate entities that will be involved in the financing.
                You will be asked to profile your financial wherewithal (TNW, Liquidity, and average annual income) of
                the borrower/guarantors. This provides the basis for a discussion of which lenders would be most
                appropriate, and with our knowledge, what the most competitive structure available (money down, term,
                amortization, and interest rate) is for your credit profile. This lender pool is limited to no more than
                3-5 lenders.
              </p>
            </div>
            <div>
              <p>
                These discussions are limited to only Don Synborski, Managing Director of Finance to maintain
                confidentiality, and further protected by a Non-Disclosure Agreement that does not permit the sharing of
                your financial information within or outside our company without a similar non-disclosure agreement
                between the parties.
              </p>
            </div>
            <div>
              <p>
                If you agree to move forward, a term sheet will be prepared that summarizes the structure and terms
                agreed upon and distributed to the lender pool. Only bids that match or improve upon the terms we desire
                will be further considered. Once we mutually agree upon the most desirable bid, that lender will be
                asked to move forward, complete their due diligence, and seek approval.
              </p>
            </div>
            <div>
              <p>
                We remain involved through the entire process: due diligence, approval, documentation, and funding to
                constantly provide our market insight, to avoid delay, and efficiently finalize your aircraft loan.
              </p>
            </div>
          </Content>
        </div>
      </div>
    </div>
  );
};

export default withWidth(TheFinancialAdvisoryProcess);
