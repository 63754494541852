import React from "react";

import "./font.css";

import axios from "axios";
import { hot, setConfig } from "react-hot-loader";

import Notification from "./components/global/Notification";
import Nav from "./components/nav/Nav";
// import planes from '../public/backup/data.json';

import ScrolledNav from "./components/nav/ScrolledNav";
import ScrollToTop from "./helpers/ScrollToTop";
import { GlobalStyle } from "./helpers/styles";
import Routes from "./Routes";

// Removes react-hot-loader console warning
setConfig({
  showReactDomPatchNotification: false,
});
let apiDomain = process.env.REACT_APP_PROD_API;

if (process.env.REACT_APP_ENVIRONMENT !== "production") {
  apiDomain = process.env.REACT_APP_DEV_API;
}

const alphabetically = (field, ascending) => {
  return function (a, b) {
    // equal items sort equally
    if (a[field] === b[field]) {
      return 0;
    }
    // nulls sort after anything else
    if (!a[field]) {
      return 1;
    }
    if (!b[field]) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return a[field].localeCompare(b[field]);
    }
    // if descending, highest sorts first

    return b[field].localeCompare([field]);
  };
};

const numeric = (field) => {
  return (a, b) => {
    return b[field] - a[field] || (a[field] != null) - (b[field] != null);
  };
};

class App extends React.Component {
  input = React.createRef();

  checkbox = React.createRef();

  state = {
    planes: [],
    scrolledNav: false,
    lockScroll: false,
    introAnimation: false,
    hideCovid: false,
    notification: {},
  };

  toggleScrolledNav = (bool) => {
    this.setState({
      scrolledNav: bool,
    });
  };

  toggleHideCovid = (bool) => {
    this.setState({
      hideCovid: bool,
    });
  };

  toggleLockScroll = (bool) => {
    this.setState({
      lockScroll: bool,
    });
  };

  componentDidUpdate() {
    const htmlStyle = document.querySelector("html").style;

    if (this.state.lockScroll) {
      htmlStyle.cssText = "overflow: hidden;";
    } else {
      htmlStyle.cssText = null;
    }
  }

  setIntroAnimation = (bool) => {
    this.setState({
      introAnimation: bool,
    });
  };

  sortPlanes = (sortType) => {
    const { value } = sortType;
    const { planes } = this.state;
    let sortedPlanes;

    switch (value) {
      case "manufacturer a-z":
        sortedPlanes = planes.sort(alphabetically("manufacturer", true));
        break;

      case "manufacturer z-a":
        sortedPlanes = planes.sort(alphabetically("manufacturer", false));
        break;

      case "most passengers":
        sortedPlanes = planes.sort(numeric("total_passengers"));
        break;

      case "newest model year":
        sortedPlanes = planes.sort(numeric("year_model"));
        break;

      case "longest range":
        sortedPlanes = planes.sort(numeric("flight_range"));
        break;

      case "price":
        sortedPlanes = planes.sort(numeric("price"));
        break;

      default:
        sortedPlanes = planes;
        break;
    }

    this.setState({
      planes: sortedPlanes,
    });
  };

  componentDidMount() {
    const that = this;

    axios({
      method: "get",
      url: `${apiDomain}/aircraft`,
    }).then(function (response) {
      const aircrafts = response.data;
      that.setState({
        planes: aircrafts.sort(numeric("price")),
      });
    });

    axios({
      method: "get",
      url: `${apiDomain}/notifications`,
    }).then(function (response) {
      const visibleNotifications = [];
      for (let x = 0; x < response.data.length; x++) {
        if (response.data[x].is_visible) {
          visibleNotifications.push(response.data[x]);
        }
      }

      that.setState({
        notification: visibleNotifications[0] || visibleNotifications,
      });
    });
  }

  render() {
    return (
      <>
        <ScrollToTop />
        <GlobalStyle />
        <Notification
          lockScroll={this.state.lockScroll}
          toggleLockScroll={this.toggleLockScroll}
          toggleHideCovid={this.toggleHideCovid}
          notification={this.state.notification}
        />
        <Nav notification={this.state.notification} />
        <ScrolledNav
          toggleScrolledNav={this.toggleScrolledNav}
          scrolledNav={this.state.scrolledNav}
          lockScroll={this.state.lockScroll}
          toggleLockScroll={this.toggleLockScroll}
          notification={this.state.notification}
        />
        <Routes
          planes={this.state.planes}
          scrolledNav={this.state.scrolledNav}
          toggleScrolledNav={this.toggleScrolledNav}
          toggleLockScroll={this.toggleLockScroll}
          onSort={this.sortPlanes}
          introAnimation={this.state.introAnimation}
          setIntroAnimation={this.setIntroAnimation}
        />
      </>
    );
  }
}

export default hot(module)(App);
