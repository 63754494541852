import React from "react";

import styled from "styled-components";

import type { WithWidthProps } from "../../../hoc/withWidth";
import { withWidth } from "../../../hoc/withWidth";

const leftStripeWidthPixels = 20;

const solutionsHeightPixels = 200;

const List = styled("ul")`
  list-style: none;

  li {
    margin-bottom: 2rem;
  }

  li:before {
    content: "✓";
    padding-right: 1rem;
  }
`;

const Content = styled("div")`
  > div {
    padding-bottom: 1rem;
  }
`;

const AvproFinancingSolutions = (props: WithWidthProps) => {
  const showSolutions = props.isUp("md");
  return (
    <div
      id="avpro-financing-solutions"
      style={{
        position: "relative",
        display: "flex",
        // height: props.isUp("lg") ? "850px" : props.isUp("md") ? "1200px" : "1500px",
        minHeight: "500px",
        fontFamily: "source-sans-pro",
        color: "rgb(56, 56, 56)",
        background: "url('../images/financial-services/Page 2 Image.jpg')",
        backgroundSize: "cover",
      }}
    >
      <div
        style={{
          width: `${leftStripeWidthPixels}px`,
          background: "rgb(60, 134, 163)",
        }}
      />
      <div style={{ width: "50px" }} />
      <Content
        style={{
          flex: 1,
          background: "rgb(255, 255, 255, 0.8)",
          padding: props.isUp("lg") ? "8rem" : props.isUp("md") ? "4rem" : "1rem",
          paddingTop: "2rem",
          paddingBottom: "1rem",
          fontSize: "14pt",
          fontFamily: "realistwide",
        }}
      >
        <div
          style={{
            fontFamily: "Selawik Semibold",
            fontSize: props.isUp("lg") ? "28pt" : props.isUp("md") ? "24pt" : "18pt",
          }}
        >
          <h2 style={{ margin: 0 }}>Avpro Financing Solutions</h2>
        </div>
        <div>
          <p>
            Once again leading the market, Avpro now offers Financial Advisory Services. We offer an attractive new
            source of capital, preserving your existing loan capacity for future investments to grow your business.
          </p>
        </div>
        <div style={{ fontWeight: "bold" }}>
          <List>
            <li>Available to all borrowers - existing or new clients</li>
            <li>You do not need to be an Avpro client to utilize our Financial Advisory Services</li>
            <li>Competitive terms</li>
            <li>
              Rapid turnaround and closing timelines to meet the response timeframe required within your Aircraft
              Purchase Agreement
            </li>
            <li>
              Cashflow relief with lenders willing to lengthen amortization to lessen the impact of the higher rate
              environment
            </li>
          </List>
        </div>
        <div>
          <p>
            Our constant interaction with the whole range of aircraft lenders creates efficiency. We know each lenders
            current practices and hot buttons, which assures attractive terms, efficient approval, documentation, and
            closing processes - avoiding delay over irrelevant or unachievable goals.
          </p>
        </div>
      </Content>
      {showSolutions && (
        <div
          style={{
            padding: "2rem",
            textAlign: "center",
            fontSize: props.isUp("xl") ? "80pt" : props.isUp("lg") ? "64pt" : "54pt",
            color: "white",
            marginTop: "auto",
          }}
        >
          <span style={{ marginTop: `${solutionsHeightPixels / 4}px`, fontFamily: "source-sans-pro", fontWeight: 200 }}>
            SOLUTIONS
          </span>
        </div>
      )}
    </div>
  );
};

export default withWidth(AvproFinancingSolutions);
