import React from "react";

import styled from "styled-components";

import { vars } from "../../../helpers/styles";
import type { WithWidthProps } from "../../../hoc/withWidth";
import { withWidth } from "../../../hoc/withWidth";

const List = styled("ul")`
  list-style-type: circle;

  & a {
    all: unset;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const TableOfContents = (props: WithWidthProps) => {
  const sidebarWidthPixels = props.isUp("lg") ? 400 : 0;
  return (
    <div
      style={{
        position: "relative",
        height: "850px",
        minHeight: "700px",
        fontFamily: "source-sans-pro",
        paddingTop: vars.navBar,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: -1,
          background: "url('../images/financial-services/Cover Image.jpg')",
          backgroundSize: "cover",
          display: "flex",
        }}
      />
      {sidebarWidthPixels > 0 && (
        <div
          style={{
            position: "absolute",
            right: 0,
            width: `${sidebarWidthPixels}px`,
            top: vars.navBar,
            bottom: "20px",
            border: "1px solid white",
            background: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontSize: "16pt",
            fontFamily: "realistwide",
            overflow: "hidden",
          }}
        >
          <List>
            <li style={{ paddingBottom: "15%" }}>
              <a href="#avpro-financing-solutions">Avpro Offers Financing Solutions</a>
            </li>
            <li style={{ paddingBottom: "15%" }}>
              <a href="#the-avpro-financial-advantage">The Avpro Financial Advantage</a>
            </li>
            <li style={{ paddingBottom: "15%" }}>
              <a href="#should-i-consider-new-lender">Should I Consider A New Lender?</a>
            </li>
            <li style={{ paddingBottom: "15%" }}>
              <a href="#the-financial-advisory-process">The Financial Advisory Process</a>
            </li>
            <li style={{ paddingBottom: "15%" }}>
              <a href="#stretch-amortization">Amortization Cash Flow Savings</a>
            </li>
          </List>
        </div>
      )}
      <div style={{ position: "absolute", bottom: "20px", width: `calc(100% - ${sidebarWidthPixels}px)` }}>
        <div style={{ height: "20px", background: "white" }} />
        <div style={{ padding: "1rem", paddingLeft: "2rem", color: "rgb(224,235,246)" }}>
          <span
            style={{ fontWeight: "bold", fontSize: props.isUp("xl") ? "56pt" : props.isUp("lg") ? "48pt" : "32pt" }}
          >
            Avpro Financial Services
          </span>
        </div>
        <div
          style={{
            background: "white",
            color: "rgb(20, 53, 83)",
            fontSize: props.isUp("md") ? "16pt" : "14pt",
            paddingLeft: "2rem",
          }}
        >
          <div style={{ display: "flex", fontWeight: "bold" }}>
            <div style={{ padding: "0.25rem", flex: 1 }}>
              <span>Don Synborski</span>
            </div>
            <div style={{ padding: "0.25rem", flex: 1 }}>
              <span>dsynborski@avprojets.com</span>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ padding: "0.25rem", flex: 1 }}>
              <span>Managing Director of Finance</span>
            </div>
            <div style={{ padding: "0.25rem", flex: 1 }}>
              <span>Office (410) 573-1515 ext. 116</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withWidth(TableOfContents);
