import React from "react";

import styled from "styled-components/macro";

import {
  vars,
  colors,
  font,
  mediaQuery,
  Section,
  SectionContent,
  SectionTitle,
  Wrapper,
} from "../../../helpers/styles";

const PlaneHighlights = (props) => {
  function detail(key) {
    return props.details[key];
  }

  if (detail("highlight1") && detail("highlight2") && detail("highlight3")) {
    return (
      <Section ref={props.highlightRef}>
        <Wrapper>
          <SectionContent>
            <SectionTitle>Highlights</SectionTitle>
            <Highlights>
              <Highlight>
                <HighlightText>{detail("highlight1")}</HighlightText>
              </Highlight>
              <Highlight>
                <HighlightText>{detail("highlight2")}</HighlightText>
              </Highlight>
              <Highlight>
                <HighlightText>{detail("highlight3")}</HighlightText>
              </Highlight>
              <HighlightGraphic />
            </Highlights>
          </SectionContent>
        </Wrapper>
      </Section>
    );
  }
  return null;
};

export default PlaneHighlights;

const Highlights = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  position: relative;

  @media ${mediaQuery.md} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Highlight = styled.div`
  padding: ${vars.gridGap}px 0 ${vars.gridGap}px calc(50px + ${vars.gridGap}px);

  @media ${mediaQuery.md} {
    padding: calc(85px + ${vars.gridGap * 3.5}px) ${vars.gridGap}px ${vars.gridGap * 2}px;
    border-top: 1px solid ${colors.alto};
    border-bottom: 1px solid ${colors.alto};

    &:nth-of-type(2),
    &:nth-of-type(3) {
      border-left: 1px solid ${colors.alto};
    }
  }
`;

const HighlightText = styled.p`
  ${font({
    name: "realist",
    size: "18px",
    weight: 400,
    color: colors.black,
  })};
  margin: 0;

  @media ${mediaQuery.md} {
    text-align: center;
  }
`;

const HighlightGraphic = styled.div`
  position: absolute;
  left: 0;
  background: url("../images/highlight-sm.svg") center center/contain no-repeat;
  height: 80%;
  width: 50px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  @media ${mediaQuery.md} {
    top: ${vars.gridGap * 2}px;
    left: 0;
    right: 0;
    max-height: 85px;
    height: 100%;
    background: url("../images/highlight.svg") center center/contain no-repeat;
    width: 80%;
    margin: 0 auto auto;
  }
`;
