import React from "react";

import styled from "styled-components/macro";

import { colors, Section } from "../../../helpers/styles";

const HiddenImage = (props) => {
  return <HiddenImageTag src={props.sourceImage} alt="" />;
};

export default HiddenImage;

const HiddenImageTag = styled.img`
  width: 2px;
  height: 2px;
  display: none;
`;
