import React from "react";

import styled from "styled-components/macro";

import {
  vars,
  colors,
  Wrapper,
  mediaQuery,
  Section as section,
  SectionTitle as sectionTitle,
  SectionText as sectionText,
} from "../../../helpers/styles";
import { Grid as grid, GridDataCell, GridCell, Label, Value } from "../../global/Grid";

const ServicesTransactionDoc = (props) => {
  return (
    <Section clearTop>
      <Wrapper>
        <Content>
          <SectionTitle>Transaction &amp; Documentation</SectionTitle>
          <SectionText>
            Our Transaction Team has overseen thousands of complex transactions, and they apply that experience
            preparing, reviewing, and approving:
          </SectionText>
          <Grid>
            <GridDataCell label="Sale Agreement" value="Letters of Intent and Purchase Agreements" />
            <GridDataCell label="Documentation" value="Marketing Agreements & Acquisition Agreements" />
          </Grid>
          <SectionText>
            Our Finance and Closing Department closes an average of 100 transactions per year, both domestically and
            internationally, and utilizes that experience to facilitate a seamless process to include:
          </SectionText>
          <Grid>
            <GridDataCell
              label="Escrow"
              value="Liaising with escrow agents to obtain lien status and facilitate document and fund placement"
            />
            <GridDataCell
              label="Closing Checklists"
              value="Preparing detailed checklists outlining all required items and documents for closing"
            />
            <GridDataCell
              label="Document Preparation"
              value="Knowledge of and preparation of all required FAA and
              International Registry forms"
            />
            <GridDataCell label="Lien/Loan" value="Coordination with lenders and lien holders" />
            <GridDataCell
              label="Acquisition Structuring"
              value="Recommendations for Owner Trustees and non-standard
              acquisition structures"
            />
            <GridDataCell label="Maintenance Programs" value="Coordination of maintenance program transfers" />
          </Grid>
        </Content>
      </Wrapper>
    </Section>
  );
};

export default ServicesTransactionDoc;

const Section = styled(section)`
  background: ${colors.alabaster};
`;

const Content = styled.div``;

const SectionTitle = styled(sectionTitle)`
  color: ${colors.bermudaGray};
`;

const Grid = styled(grid)`
  margin-top: ${vars.gridGap * 2}px;

  @media ${mediaQuery.sm} {
    padding: 1px;
  }

  ${GridCell} {
    padding: ${vars.gridGap}px ${vars.gridGap / 2}px;

    @media ${mediaQuery.sm} {
      padding: ${vars.gridGap}px ${vars.gridGap * 2}px;
    }
  }

  ${Label} {
    font-weight: 800;
  }

  ${Value} {
    font-weight: 200;
  }
`;

const SectionText = styled(sectionText)`
  font-weight: 400;

  ${Grid} + & {
    margin-top: ${vars.gridGap * 3}px;
  }
`;
