import React, { useState, useRef } from "react";

import queryString from "query-string";

import { removeParamFromURL, updateURL } from "../../../hooks";
import {
  OptionHeader,
  OptionTitle,
  OptionClear,
  Options,
  Option,
  OptionInput,
  OptionLabel,
} from "../../global/Options";

const FilterRange = (props) => {
  const { history, location, scrollToMain } = props;
  const radioRef1 = useRef();
  const radioRef2 = useRef();
  const radioRef3 = useRef();

  const [optionSelected, setOptionSelected] = useState(false);

  const g = queryString.parse(location.search);

  const handleClear = (e) => {
    e.preventDefault();
    setOptionSelected(false);

    radioRef1.current.checked = false;
    radioRef2.current.checked = false;
    radioRef3.current.checked = false;

    const remove = removeParamFromURL("flight_range", g);
    history.push(`/${remove}`);
  };

  const handleRange = (e) => {
    const newURL = updateURL(g, "flight_range", location.search, e.target.value);

    setOptionSelected(true);

    history.replace(`/${newURL}`);

    scrollToMain();
  };

  return (
    <>
      <OptionHeader>
        <OptionTitle>Range (NM)</OptionTitle>
        <OptionClear optionSelected={optionSelected} onClick={(e) => handleClear(e)}>
          Clear
        </OptionClear>
      </OptionHeader>
      <Options>
        <Option>
          <OptionInput
            type="radio"
            name="range"
            id="range1"
            ref={radioRef1}
            onClick={(e) => handleRange(e)}
            value="isLess-4000"
          />
          <OptionLabel htmlFor="range1" isActive={!!(g.flight_range && g.flight_range === "isLess-4000")}>
            {"<4k"}
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type="radio"
            name="range"
            id="range2"
            ref={radioRef2}
            onClick={(e) => handleRange(e)}
            value="4000-6000"
          />
          <OptionLabel htmlFor="range2" isActive={!!(g.flight_range && g.flight_range === "4000-6000")}>
            4k-6k
          </OptionLabel>
        </Option>
        <Option>
          <OptionInput
            type="radio"
            name="range"
            id="range3"
            ref={radioRef3}
            onClick={(e) => handleRange(e)}
            value="isGreater-6000"
          />
          <OptionLabel htmlFor="range3" isActive={!!(g.flight_range && g.flight_range === "isGreater-6000")}>
            6k+
          </OptionLabel>
        </Option>
      </Options>
    </>
  );
};

export default FilterRange;
