import React from "react";

import { darken } from "polished";
import queryString from "query-string";
import styled from "styled-components/macro";

import { vars, colors, font } from "../../../helpers/styles";
import { updateURL } from "../../../hooks";
import { Icon, ChevronRight } from "../../Icons";

const FilterCabinClass = (props) => {
  const g = queryString.parse(props.location.search);

  const handleClick = (cabin) => {
    const newURL = updateURL(g, "cabin_class", props.location.search, cabin);
    props.history.push(`/${newURL}`);
    props.scrollToMain();
  };

  const filterLength = (cabinClass) => {
    const filter = props.planes.filter((plane) => plane.cabin_class.includes(cabinClass));

    return filter.length;
  };

  const CabinOption = (option) => (
    <Option
      key={`cabins-${option.title.replace(" ", "-").toLowerCase()}`}
      onClick={() => {
        handleClick(option.title);
      }}
    >
      <OptionContent>
        <OptionTitle>{option.title}</OptionTitle>
        <OptionText>{option.text}</OptionText>
      </OptionContent>
      <Cta>
        <CtaText>Show</CtaText>
        <CtaNumber>{filterLength(option.title)}</CtaNumber>
        <CtaIconBox>
          <CtaIcon>
            <ChevronRight />
          </CtaIcon>
        </CtaIconBox>
      </Cta>
    </Option>
  );

  return (
    <Filters>
      <Filter>
        <Label>Select a Cabin Class</Label>
        {props.cabins.map((cabin) =>
          CabinOption({
            title: cabin.title,
            text: cabin.text,
          }),
        )}
      </Filter>
    </Filters>
  );
};

export default FilterCabinClass;

const Filters = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${vars.gridGap * 2}px ${vars.gridGap * 2}px;
`;

const Filter = styled.div`
  padding-bottom: 100px;
`;

const Label = styled.p`
  ${font({
    name: "realist",
    size: "18px",
    weight: 400,
    color: colors.black,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  margin: 0 0 ${vars.gridGap * 1.5}px;
`;

const Option = styled.button`
  background: ${colors.tea};
  border: none;
  display: flex;
  flex-direction: column;
  padding: ${vars.gridGap * 1.5}px;
  position: relative;
  cursor: pointer;
  transition: 250ms;

  & + & {
    margin-top: ${vars.gridGap / 2}px;
  }

  &::after {
    opacity: 0.2;
    position: absolute;
    top: ${vars.gridGap}px;
    right: ${vars.gridGap}px;
    content: "";
    display: block;
    width: 100px;
    height: 60px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 106.58 63.9'%3E %3Cpath d='M43.4,17.77,40,3.15l5.39-.33L63.41,17.68l1.75-2.14L46.27,0,36.53.59,40.72,18.4Z' style='fill: %238c8070'/%3E %3Cpath d='M93.5,20.21l-74,4.92-7.87-6.75L0,18.3,11.62,38.64l11.24,3.65,22.86-1L50,63.15l9,.75L69.74,39.7l34.11-2,2.73-8.4ZM101.8,35l-33.9,2L57.24,61l-5-.42L47,33.49,44.31,34l.88,4.52-21.95,1-9.76-3.17L4.77,21.09l5.81,0,8,6.83L92.72,23l10.6,7.33Z' style='fill: %238c8070'/%3E %3C/svg%3E ")
      center center/contain no-repeat;
  }

  &:hover,
  &:focus {
    background: ${darken(0.06, colors.tea)};
    box-shadow: inset 0 0 0 3px ${colors.makara};
    outline: none;
  }
`;

const OptionContent = styled.div`
  text-align: start;
`;

const OptionTitle = styled.p`
  ${font({
    name: "realist",
    size: "25px",
    weight: 400,
    color: colors.mineShaft,
    letterSpacing: "0.5px",
  })};
  margin: 0 0 ${vars.gridGap / 4}px;
`;

const OptionText = styled.p`
  ${font({
    name: "realist",
    size: "14px",
    weight: 400,
    color: "#787672",
    letterSpacing: "0.5px",
  })};
  margin: 0;
`;

const Cta = styled.div`
  display: flex;
  align-items: center;
  margin: ${vars.gridGap / 2}px 0 0 auto;
`;

const CtaText = styled.p`
  ${font({
    name: "realist",
    size: "14px",
    weight: 600,
    color: colors.mineShaft,
    letterSpacing: "0.5px",
    transform: "uppercase",
  })};
  display: block;
  margin: 3px 0 0;
`;

const CtaNumber = styled(CtaText)`
  font-size: 20px;
  margin: 0 0 0 ${vars.gridGap / 2}px;
`;

const CtaIconBox = styled.span`
  height: 25px;
  width: 25px;
  border-radius: 50px;
  background-color: ${colors.makara};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 200ms;
  margin-left: ${vars.gridGap / 2}px;
`;

const CtaIcon = styled(Icon)`
  height: 10px;
  width: 10px;
  fill: ${colors.white};
`;
